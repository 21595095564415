import { FieldArray, Form, Formik } from 'formik';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { } from "primereact/iconbase";
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import Breadcrumb from 'src/components/Common/Breadcrumb';import InputCom from 'src/components/Common/Input';
import { useToast } from 'src/components/Common/ReactToaster';
import StatusHghlighter from 'src/components/Common/StatusHighlighter/StatusHighlighter';
import ValidationPopup from 'src/components/Common/ValidationMessage/ValidationPopup';
import DataTableWrapper from 'src/components/Grid/DataTableWrapper';
import Button from 'src/components/UI/Button';
import CheckboxInput from 'src/components/UI/CheckboxInput';
import DropdownInput from 'src/components/UI/DropdownInput';
import TooltipMEL from 'src/components/UI/Tooltip';
import { ROUTER } from 'src/constants/routes';
import { formatDateTimeFormServerView, hasAdminOrEmployeeRoleHandler, hasFranchiseRoleHandler, hasFranchiseSubuserRoleHandler } from 'src/helpers/common_helpers';
import { Columns } from 'src/models/components/dataTableWrapperModel';
import {
  addInvoiceDetailLoading,
  getCustomerDropForInvoiceLoading,
  getDealerDropForInvoiceLoading,
  getInvoicePageInvoiceListLoading,
  getInvoicePageJobSheetIdRelatedDetailsLoading,
  getInvoicePageJoblistListLoading,
  getInvoiceTypeDropdownListLoading,
  getInvoicesIdLoading,
  getJobEstimateDtoDetailsLoading,
  getPartsListDropForInvoiceLoading,
  getUndoPartsSkuListLoading,
  getUpdatedByNameLoading,
  gettaxesListDropForInvoiceLoading,
  invoiceUpdateLoading,
  jobSheetReturnInvoiceDetailsLoading,
  removePartsSkuDetails,
  resetInvoicePageDetails,
  resetInvoicePageMessages,
  resetPartsSkuIndex,
  resetonChnagesTaxDetails,
  resetundoPartsSkuIndex,
} from 'src/store/actions';
import { RootReducerState } from 'src/store/reducers';
import * as Yup from "yup";
import FeatherIcon from "feather-icons-react";
import InvoiceScanner from './InvoiceScanner';
import { Dialog } from 'primereact/dialog';
import TaxesDetails from './TaxesDetails';
import { READY_TO_INVOICE } from 'src/constants/JobSheetStatus';
import TextEditor from 'src/components/atoms/TextEditor';
import HtmltoPlainText from 'src/components/atoms/HtmltoPlainText';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';



const AddEditInvoice = () => {
  const { id, job_id, edit_job_id } = useParams();
  const formValue = useSelector((state: RootReducerState) => state.invoiceReducer.invoiceDetails);
  const { loading, error, message, customerList, dealerList, partsSkuList, taxesList, push, partsSkuIndex, partsSetFieldValue, taxByIdDetails, invoiceDropdown, jobListDropdown, jobIdRelatedDetails, passtaxesDetails, jobSheetReturnInvoice, taxesDropdownList, updatedByName, editJobEstimateDto, ediJobIsInwarranty, editJobsheetStatus, editJobIsExchange } = useSelector((state: RootReducerState) => state.invoiceReducer);
  const { index, response, setFieldValue, value, formValues } = useSelector((state: RootReducerState) => state.invoiceReducer.taxOnchangesDetails);

  const { undopartskuindex, undopartskuresponse, undosetFieldValue, undoFormValues, partSkuId } = useSelector((state: RootReducerState) => state.invoiceReducer.undoPartSkuDetails);
  const jobEstimateDto = jobIdRelatedDetails?.jobEstimateDto;

  let generalService = "General / Other Service Invoice";
  let salesService = "Sales Invoice";


  // const taxDrop: any[] = useMemo(() => {
  //   return taxesList.length > 0 ? taxesList?.map((eachTax: any) => {
  //     let data: any = {}
  //     data['label'] = eachTax?.name;
  //     data['value'] = eachTax?.name;
  //     data['id'] = eachTax?.id;
  //     return data;
  //   }).sort((a: any, b: any) => {
  //     const labelA = a.label.toUpperCase().trim();
  //     const labelB = b.label.toUpperCase().trim();
  //     if (labelA < labelB) {
  //       return -1;
  //     }
  //     if (labelA > labelB) {
  //       return 1;
  //     }
  //     return 0;
  //   }) : []
  // }, [taxesList])

  const taxDrop = taxesList?.length > 0 ? (taxesList.map((eachTax: any) => {
    let data: any = {};
    data['value'] = eachTax.name;
    data['label'] = eachTax.name;
    data['id'] = eachTax.id;
    return data;
  })).sort((a: any, b: any) => {
    const labelA = a.label.toUpperCase().trim();
    const labelB = b.label.toUpperCase().trim();
    if (labelA < labelB) {
      return -1;
    }
    if (labelA > labelB) {
      return 1;
    }
    return 0;
  }) : [];


  const invoiceTaxTypes = useMemo(() => {
    return taxesDropdownList.length > 0 ? taxesDropdownList?.map((eachTax: any) => {
      let data: any = {};
      data['label'] = eachTax?.taxName;
      data['value'] = eachTax?.id;
      return data;
    }).sort((a: any, b: any) => {
      const labelA = a.label.toUpperCase().trim();
      const labelB = b.label.toUpperCase().trim();
      if (labelA < labelB) {
        return -1;
      }
      if (labelA > labelB) {
        return 1;
      }
      return 0;
    }) : [];
  }, [taxesDropdownList]);


  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [flatemap, setFlatmap] = useState<any>([]);
  const [dynamicTaxField, setDynamicTaxField] = useState<any>([]);
  const [errorValidation, setErrorValidation] = useState<any>(false);
  const [totalValue, setTotalValue] = useState<any>(0);
  const [undoFocDetails, setUndoFocDetails] = useState<any>([]);
  const [newErrorvisible, setNewErrorVisible] = useState(false);
  const [mismatchMessage, setMisMatchMessage] = useState("");
  const [EstimatesDetails, setEstimatesDetails] = useState<any>([]);
  const [reintializeField, setReintializeField] = useState({
    comments: "",
    discount: 0,
    invoiceTypeName: 0,
    locked: false,
    taxTypeId: ""
  });
  const [partsSkugenerator, setPartsSkugenerator] = useState({
    index: 0,
    setFieldValue: null,
    passpartSkuDetails: "",
    passtaxesDetails: "",
    passdeleteState: false,
    reducerState: false,
    push: () => { }
  });

  const [openScanner, setOpenScanner] = useState<boolean>(false); //scanner implementation

  const AssignInvoiceType = () => {

    if (Object.keys(jobIdRelatedDetails).length > 1 && jobIdRelatedDetails?.isExchange == false && jobSheetReturnInvoice.length == 0) {
      return 3; //service invoice
      //2 general and other
      //1 sales invoice
    } else if (Object.keys(jobIdRelatedDetails).length > 1 && jobIdRelatedDetails?.isExchange == false && jobSheetReturnInvoice.length > 0 && jobSheetReturnInvoice[0]?.invoiceTypeId == 3) {
      if (jobSheetReturnInvoice[0].locked == true) {
        return 2;
      } else {
        return jobSheetReturnInvoice[0]?.invoiceTypeId;
      }
    } else if (Object.keys(jobIdRelatedDetails).length > 1 && jobIdRelatedDetails?.isExchange == false && jobSheetReturnInvoice.length > 0 && jobSheetReturnInvoice[0]?.invoiceTypeId == 2) {
      if (jobSheetReturnInvoice[0].locked == true) {
        return 3;
      } else {
        return jobSheetReturnInvoice[0]?.invoiceTypeId;
      }
    }
    else if (Object.keys(jobIdRelatedDetails).length > 1 && jobIdRelatedDetails?.isExchange == true && jobSheetReturnInvoice.length == 0 && hasAdminOrEmployeeRoleHandler()) {
      return 2;
    }
    else if (Object.keys(jobIdRelatedDetails).length > 1 && jobIdRelatedDetails?.isExchange == true && jobSheetReturnInvoice.length == 0 && (
      hasFranchiseRoleHandler() || hasFranchiseSubuserRoleHandler()
    )) {
      return 3;
    }
    else if (Object.keys(jobIdRelatedDetails).length > 1 && (jobIdRelatedDetails?.isExchange == true && jobSheetReturnInvoice.length <= 1 && jobSheetReturnInvoice[0]?.invoiceTypeId == 2)) {
      return 3;
    } else if (Object.keys(jobIdRelatedDetails).length > 1 && (jobIdRelatedDetails?.isExchange == true && jobSheetReturnInvoice.length <= 1 && jobSheetReturnInvoice[0]?.invoiceTypeId == 3)) {
      return 2;
    } else if (Object.keys(jobIdRelatedDetails).length > 1 && (jobIdRelatedDetails?.isExchange == true && jobSheetReturnInvoice.length == 2)) {
      return 3;
    } else {
      return "";
    }
  };

  const bindTaxesValue = () => {
    if ((!id && !job_id) && dynamicTaxField?.length > 0) {
      return dynamicTaxField?.slice(0, 5)?.map((eachTax: any) => {
        let data: any = {};
        data["id"] = eachTax.id,
          data["name"] = eachTax.name,
          data["sacNo"] = eachTax.sacNumber ?? eachTax.sacNo,
          data["taxPercentage"] = eachTax.taxPercentage ?? eachTax.taxPrc,
          data["quantity"] = eachTax.quantity ? eachTax.quantity : 0,
          data["unitPrice"] = eachTax.unitPrice ? eachTax.unitPrice : 0,
          data["amount"] = eachTax.amount ? eachTax.amount : 0,
          data["taxAmount"] = eachTax.totalTax ? eachTax.totalTax : 0,
          data["totalAmount"] = eachTax.amountWithTax ? eachTax.amountWithTax : 0;
        return data;
      });
    } else if ((id && !job_id) && Object.keys(jobIdRelatedDetails).length == 0) {
      return formValue?.["taxes"]?.map((eachTax: any) => {
        let data: any = {};
        data["id"] = eachTax.id,
          data["name"] = eachTax.name,
          data["sacNo"] = eachTax.sacNumber ?? eachTax.sacNo,
          data["taxPercentage"] = eachTax.taxPercentage ?? eachTax.taxPrc,
          data["quantity"] = eachTax.quantity ? eachTax.quantity : 0,
          data["unitPrice"] = eachTax.unitPrice ? eachTax.unitPrice : 0,
          data["amount"] = eachTax.amount ? eachTax.amount : 0,
          data["taxAmount"] = eachTax?.totalTaxAmount ? eachTax?.totalTaxAmount : 0,
          data["totalAmount"] = eachTax.totalAmount ? eachTax.totalAmount : 0;
        return data;
      });
    } else if ((id && !job_id) && dynamicTaxField?.length > 0) {
      return dynamicTaxField?.slice(0, 5)?.map((eachTax: any) => {
        let data: any = {};
        data["id"] = eachTax.id,
          data["name"] = eachTax.name,
          data["sacNo"] = eachTax.sacNumber ?? eachTax.sacNo,
          data["taxPercentage"] = eachTax.taxPercentage ?? eachTax.taxPrc,
          data["quantity"] = eachTax.quantity ? eachTax.quantity : 0,
          data["unitPrice"] = eachTax.unitPrice ? eachTax.unitPrice : 0,
          data["amount"] = eachTax.amount ? eachTax.amount : 0,
          data["taxAmount"] = 0,
          data["totalAmount"] = eachTax.totalAmount ? eachTax.totalAmount : 0;
        return data;
      });
    } else if ((job_id && !id) && dynamicTaxField?.length > 0) {
      return dynamicTaxField?.slice(0, 5)?.map((eachTax: any) => {
        let data: any = {};
        data["id"] = eachTax.id,
          data["name"] = eachTax.name,
          data["sacNo"] = eachTax.sacNumber ?? eachTax.sacNo,
          data["taxPercentage"] = eachTax.taxPercentage ?? eachTax.taxPrc,
          data["quantity"] = eachTax.quantity ? eachTax.quantity : 1,
          data["unitPrice"] = eachTax.amount ? eachTax.amount : 0,
          data["amount"] = eachTax.amount ? eachTax.amount : 0,
          data["taxAmount"] = parseFloat(eachTax?.totalTax),
          data["totalAmount"] = eachTax.amountWithTax ? parseFloat(eachTax.amountWithTax) : 0;
        return data;
      });
    } else {
      return dynamicTaxField?.slice(0, 5)?.map((eachTax: any) => {
        let data: any = {};
        data["id"] = eachTax.id,
          data["name"] = eachTax.name,
          data["sacNo"] = eachTax.sacNumber ?? eachTax.sacNo,
          data["taxPercentage"] = eachTax.taxPercentage ?? eachTax.taxPrc,
          data["quantity"] = eachTax.quantity ? eachTax.quantity : 0,
          data["unitPrice"] = eachTax.unitPrice ? eachTax.unitPrice : 0,
          data["amount"] = eachTax.amount ? eachTax.amount : 0,
          data["taxAmount"] = parseFloat(eachTax?.totalTax),
          data["totalAmount"] = eachTax.amountWithTax ? parseFloat(eachTax.amountWithTax) : 0;
        return data;
      });
    }
  };


  const bindBillto = () => {
    if (Object.keys(jobIdRelatedDetails).length > 0) {
      if (jobIdRelatedDetails.customerId && !jobIdRelatedDetails.dealerId) {
        const customer = customerList?.filter((data) => data.id == jobIdRelatedDetails.customerId);
        return customer[0]?.userId;
      } else if (!jobIdRelatedDetails.customerId && jobIdRelatedDetails.dealerId) {
        const dealer = dealerList?.filter((data) => data.id == jobIdRelatedDetails.dealerId);
        return dealer[0]?.userId;
      } else {
        return "";
      }
    } else {
      return "";
    }
  };

  const bindTaxtype = () => {
    if (Object.keys(jobIdRelatedDetails).length > 0) {
      if (jobIdRelatedDetails.customerId && !jobIdRelatedDetails.dealerId) {
        const customer = customerList?.filter((data) => data.id == jobIdRelatedDetails.customerId);
        return customer[0]?.taxTypeId;
      } else if (!jobIdRelatedDetails.customerId && jobIdRelatedDetails.dealerId) {
        const dealer = dealerList?.filter((data) => data.id == jobIdRelatedDetails.dealerId);
        return dealer[0]?.taxTypeId;
      } else {
        const dealer = dealerList?.filter((data) => data.id == jobIdRelatedDetails.dealerId);
        return dealer[0]?.taxTypeId;
      }
    } else {
      return "";
    }
  };


  const initialvalue = useMemo(() => {
    return {
      number: ((id && formValue?.['number']) || "to be generated"),
      jobId: ((id && (formValue?.["jobSheetNumber"] && !jobIdRelatedDetails.id)) && formValue?.["jobSheetNumber"] || !job_id && jobIdRelatedDetails.id || job_id && jobIdRelatedDetails.number || ""),
      dealerName: ((id && formValue?.["dealerId"] && !jobIdRelatedDetails.dealerId) && formValue?.["dealerId"] || jobIdRelatedDetails.dealerId || ""),
      customerName: ((id && formValue?.["customerId"] && !jobIdRelatedDetails.customerId) && formValue?.["customerId"] || jobIdRelatedDetails.customerId || ""),
      billTo: formValue?.['billTo'] ? formValue?.['billTo'] : Object.keys(jobIdRelatedDetails).length > 0 ? bindBillto() : "",
      invoiceTypeName: (((id && !jobIdRelatedDetails.id) ? formValue?.["invoiceTypeId"] : (job_id && jobIdRelatedDetails?.id) ? AssignInvoiceType() : (!id && !job_id) ? "" : 3) || AssignInvoiceType()),
      serviceCharge: ((id && formValue?.["serviceCharge"] && !jobIdRelatedDetails.serviceCharge) && formValue?.["serviceCharge"] || jobIdRelatedDetails.serviceCharge || 0),
      serviceChargeTaxPrc: ((id && formValue?.["serviceChargeTaxPrc"] && !jobIdRelatedDetails.serviceChargeTaxPercentage) && formValue?.["serviceChargeTaxPrc"] || jobIdRelatedDetails.serviceChargeTaxPercentage || 0),
      serviceChargeSacNo: ((id && formValue?.["serviceChargeSacNo"] && !jobIdRelatedDetails.serviceChargeSacNumber) && formValue?.["serviceChargeSacNo"] || jobIdRelatedDetails.serviceChargeSacNumber || ""),
      discount: (formValue?.["discount"] || reintializeField.discount || 0),
      total: (totalValue || 0),
      comments: (formValue?.["comments"] || reintializeField.comments || ""),
      locked: (formValue?.["locked"] || reintializeField.locked || false),
      taxTypeId: (reintializeField.taxTypeId || Object.keys(jobIdRelatedDetails).length > 0 ? bindTaxtype() : "" || id ? formValue?.["taxTypeId"] : ""),
      partSku: flatemap.length > 0 ? flatemap?.map((eachContact: any) => {
        let data: any = {};
        data['id'] = eachContact?.id;
        data['partSkuNumber'] = eachContact?.partSkuNumber ?? eachContact.number;
        data['price'] = eachContact?.price;
        data['taxPercentage'] = eachContact?.taxPercentage ?? eachContact.taxPrc;
        data['totalPrice'] = eachContact?.totalPrice;
        data['partNumber'] = eachContact?.partNumber;
        data['partDescription'] = eachContact?.partDescription;
        data['partHsnNumber'] = eachContact?.partHsnNumber;
        data['partSkuId'] = eachContact?.partSkuId;
        data['foc'] = eachContact?.isScrap;
        return data;
      }) : [],
      taxes: bindTaxesValue()
    };
  }, [jobIdRelatedDetails, formValue, flatemap, taxByIdDetails, id, dynamicTaxField, totalValue, jobEstimateDto]);

  const { showToast } = useToast();
  const validationSchema: any = Yup.object().shape({
    jobId: Yup.string().test("ChangeJobIdrequired", "Jobsheet required", function (value) {
      const invoiceId = this.parent.invoiceTypeName;
      if (invoiceId == 3 && !value) {
        return false;
      }
      return true;
    }),
    invoiceTypeName: Yup.string().required(`Invoice Type is required`),
    serviceCharge: Yup.string().required("Service Charge is required"),
    billTo: Yup.string().required("Bill to is required"),
    dealerName: Yup.string(),
    customerName: Yup.string(),
    taxTypeId: Yup.string().required(`Tax Type is required`),
    serviceChargeTaxPrc: Yup.string().required("Service Charge Tax Percentage is required"),
    discount: Yup.string().required("Discount is required").test('not-negative', 'Discount amount cannot be negative', function (value) {
      return parseFloat(value) >= 0;
    }),
    total: Yup.number().required("Total amount is required").min(0, 'Total value cannot be negative'),
    partSku: Yup.array().of(
      Yup.object().shape({
        partSkuNumber: Yup.string()
          .required("Part Sku Number is required"),
        // .test('unique', 'Part Sku Number must be unique', function (value, context) {
        //   const { partSku }: any = context.options.context;
        //   const partSkuNumbers = partSku.map((item: any) => item.partSkuNumber);
        //   const occurrences = partSkuNumbers.filter((sku: any) => sku === value).length;
        //   return occurrences === 1;
        // }),
        price: Yup.number().required(`Price is required`).min(0, 'Price cannot be negative'),
        partNumber: Yup.string().required(`Part Number is required`),
      })),
    taxes: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required(`Name is required`).test('is-unique', 'Name must be unique', function (value) {
          let test: any = this.options?.context?.taxes?.filter((detail: any) => detail?.name === value).length;
          return test == 1;
        }),
        quantity: Yup.string().required('Quantity is required'),
        unitPrice: Yup.string().required('Unit Price is required'),
        taxPercentage: Yup.string().required('Tax Percentage is required'),
        sacNo: Yup.string().required(`Sac is required`),
        amount: Yup.number().required(`Amount is required`).min(0, 'Amount cannot be negative')
      }))
  }).test({
    name: 'one-field-required',
    test: function (value) {
      const { dealerName, customerName } = value;
      if (!dealerName && !customerName) {
        return this.createError({
          path: 'dealerName',
          message: 'Either dealer name or customer name is required',
        });
      }
      return true;
    },
  });
  const EditValidationSchema = Yup.object().shape({
    invoiceTypeName: Yup.string().required(`Invoice Type is required`),
    serviceCharge: Yup.string().required("Service Charge is required"),
    serviceChargeTaxPrc: Yup.string().required("Service Charge Tax Percentage is required"),
    billTo: Yup.string().required("Bill to is required"),
    discount: Yup.string().required("Discount is required").test('not-negative', 'Discount amount cannot be negative', function (value) {
      return parseFloat(value) >= 0;
    }),

    taxTypeId: Yup.string().required(`Tax Type is required`),
    total: Yup.number().required("Total amount is required").min(0, 'Total value cannot be negative'),
    partSku: Yup.array().of(
      Yup.object().shape({
        partSkuNumber: Yup.string()
          .required("Part Sku Number is required"),
        // .test('unique', 'Part Sku Number must be unique', function (value, context) {
        //   const { partSku }: any = context.options.context;
        //   const partSkuNumbers = partSku.map((item: any) => item.partSkuNumber);
        //   const occurrences = partSkuNumbers.filter((sku: any) => sku === value).length;
        //   return occurrences === 1;
        // }),
        price: Yup.string().required(`Price is required`),
        partNumber: Yup.string().required(`Part Number is required`),
      })),
    taxes: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required(`Name is required`).test('is-unique', 'Name must be unique', function (value) {
          let test: any = this.options?.context?.taxes?.filter((detail: any) => detail?.name === value).length;
          return test == 1;
        }),
        taxPercentage: Yup.string().required('Tax Percentage is required'),
        quantity: Yup.string().required('Quantity is required'),
        unitPrice: Yup.string().required('Unit Price is required'),
        sacNo: Yup.string().required(`Sac is required`),
        amount: Yup.number().required(`Amount is required`).min(0, 'Amount cannot be negative')
      }))
  });



  const dealerOption = dealerList?.filter((eachDealer: any) => !eachDealer.isDeleted)?.map((eachDealer: any) => {
    let data: any = {};
    data['label'] = eachDealer?.name;
    data['value'] = eachDealer?.id;
    return data;
  }).sort((a: any, b: any) => {
    const labelA = a.label.toUpperCase().trim();
    const labelB = b.label.toUpperCase().trim();
    if (labelA < labelB) {
      return -1;
    }
    if (labelA > labelB) {
      return 1;
    }
    return 0;
  });




  const jobListOption = jobListDropdown?.filter((data: any) => data.status === "Assigned To Technician")?.map((eachDealer: any) => {
    let data: any = {};
    data['label'] = eachDealer?.number ? eachDealer?.number : "WIP";
    data['value'] = eachDealer?.id;
    return data;
  }).sort((a: any, b: any) => {
    const labelA = a.label.toUpperCase().trim();
    const labelB = b.label.toUpperCase().trim();
    if (labelA < labelB) {
      return -1;
    }
    if (labelA > labelB) {
      return 1;
    }
    return 0;
  });

  const customerOption = customerList?.filter((eachCustomer: any) => !eachCustomer.isDeleted)?.map((eachCustomer: any) => {
    let data: any = {};
    data['label'] = eachCustomer?.name;
    data['value'] = eachCustomer?.id;
    return data;
  }).sort((a: any, b: any) => {
    const labelA = a.label.toUpperCase().trim();
    const labelB = b.label.toUpperCase().trim();
    if (labelA < labelB) {
      return -1;
    }
    if (labelA > labelB) {
      return 1;
    }
    return 0;
  });

  const invoiceDropOption = invoiceDropdown?.filter((eachCustomer: any) => !eachCustomer.isDeleted)?.map((eachCustomer: any) => {
    let data: any = {};
    data['label'] = eachCustomer?.name;
    data['value'] = eachCustomer?.id;
    return data;
  }).sort((a: any, b: any) => {
    const labelA = a.label.toUpperCase().trim();
    const labelB = b.label.toUpperCase().trim();
    if (labelA < labelB) {
      return -1;
    }
    if (labelA > labelB) {
      return 1;
    }
    return 0;
  });

  useEffect(() => {
    if (index != -1) {
      setFieldValue(`taxes.${index}.id`, response[0][0]?.id);
      setFieldValue(`taxes.${index}.sacNo`, response[0][0]?.sacNumber);
      setFieldValue(`taxes.${index}.taxPercentage`, response[0][0]?.taxPercentage);
      setFieldValue(`taxes.${index}.taxAmount`, 0);
      setFieldValue(`taxes.${index}.quantity`, 0);
      setFieldValue(`taxes.${index}.unitPrice`, 0);
      setFieldValue(`taxes.${index}.amount`, 0);
      setFieldValue(`taxes.${index}.totalAmount`, 0);
      handleChangeQuantity(index, 0, response[0][0]?.taxPercentage, setFieldValue, formValues?.taxes, formValues?.partSku, formValues, 0)
      dispatch(resetonChnagesTaxDetails());
    }
  }, [index]);

  useEffect(() => {
    debugger;
    if (undopartskuindex != -1) {
      let checkpartsSkuAvailabelOrNot = false;
      debugger;
      const responsePartNumber = undopartskuresponse?.filter((item: any) => item.id == partSkuId);
      const jobEstimatesArray = jobIdRelatedDetails.jobEstimateDto?.filter((data: any) => data.isDeleted == false);
      if (jobEstimatesArray?.length > 0) {
        for (const estimate of jobEstimatesArray) {
          const estimatePartDto = estimate.estimatePartDto;
          for (const part of estimatePartDto) {
            if (part.partNumber === responsePartNumber[0].partNumber) {
              checkpartsSkuAvailabelOrNot = true;
              break;
            }
          }
          if (checkpartsSkuAvailabelOrNot) {
            break;
          }
        }
      } else {
        checkpartsSkuAvailabelOrNot = true;
      }

      let partTotal = (checkpartsSkuAvailabelOrNot == false) ? 0 : responsePartNumber[0]?.totalPrice;

      undosetFieldValue(`partSku[${undopartskuindex}].id`, responsePartNumber[0]?.id);
      undosetFieldValue(`partSku[${undopartskuindex}].taxPercentage`, (checkpartsSkuAvailabelOrNot == false) ? 0 : responsePartNumber[0]?.partTaxPercentage);
      undosetFieldValue(`partSku[${undopartskuindex}].price`, (checkpartsSkuAvailabelOrNot == false) ? 0 : responsePartNumber[0]?.partPrice);
      undosetFieldValue(`partSku[${undopartskuindex}].totalPrice`, partTotal);
      undosetFieldValue(`partSku[${undopartskuindex}].partSkuNumber`, responsePartNumber[0]?.number);
      undosetFieldValue(`partSku[${undopartskuindex}].partNumber`, responsePartNumber[0]?.partNumber);
      undosetFieldValue(`partSku[${undopartskuindex}].partDescription`, responsePartNumber[0]?.partDescription);
      undosetFieldValue(`partSku[${undopartskuindex}].partHsnNumber`, responsePartNumber[0]?.partHsnNumber);
      undosetFieldValue(`partSku[${undopartskuindex}].partId`, responsePartNumber[0]?.partId);
      undosetFieldValue(`partSku[${undopartskuindex}].foc`, (checkpartsSkuAvailabelOrNot == false) ? true : false);
      setDetails({ ...details, index: details.index + 1 });
      changeTotalByPart(undopartskuindex, partTotal, undoFormValues, undosetFieldValue);
      dispatch(resetundoPartsSkuIndex());
    }
  }, [undopartskuindex]);

  useEffect(() => {
    if (formValue && Object.keys(jobIdRelatedDetails).length == 0 && !job_id) {
      let initial = 0;
      setFlatmap(formValue?.partSkus);  //initial values on getby id
      setDynamicTaxField(formValue?.["taxes"]); //initial values on getby id
      let serviceCharge = formValue?.serviceCharge;
      let serviceTax = formValue?.serviceChargeTaxPrc;
      let TotalValue = (serviceCharge * serviceTax / 100) + serviceCharge;
      const totalPartSkuTotal = formValue?.partSkus?.reduce((pre: any, current: any) => {
        return pre + current.totalPrice;
      }, initial);
      const totalTaxesTotal = formValue?.taxes?.length > 0 ? formValue?.taxes?.reduce((pre: any, current: any) => {
        return pre + current?.totalAmount;
      }, initial) : initial;
      setTotalValue((TotalValue + totalPartSkuTotal + totalTaxesTotal) - (formValue?.discount));
    }

    if (job_id && jobEstimateDto?.length == 0) {
      let calculateserviceCharge = ((jobIdRelatedDetails.serviceCharge * jobIdRelatedDetails.serviceChargeTaxPercentage) / 100) + jobIdRelatedDetails.serviceCharge;
      setTotalValue(calculateserviceCharge);
    }

    if (job_id && jobEstimateDto?.length > 0) {
      const taxes = jobEstimateDto?.filter((data: any) => !data.isDeleted)?.[0].estimateTaxDto;
      setDynamicTaxField(taxes);
      let calculateserviceCharge = ((jobIdRelatedDetails.serviceCharge * jobIdRelatedDetails.serviceChargeTaxPercentage) / 100) + jobIdRelatedDetails.serviceCharge;
      var totalAmountWithTax = 0;
      jobEstimateDto?.filter((data: any) => !data.isDeleted).forEach((item: any) => {
        item.estimateTaxDto?.forEach((taxDto: any) => {
          totalAmountWithTax += taxDto.amountWithTax;
        });
      });
      var totalAmountWithPart = 0;
      let currentTotal = calculateserviceCharge + +totalAmountWithTax;
      setTotalValue(currentTotal);
    }

    if (Object.keys(jobIdRelatedDetails).length > 0 && !job_id) {
      let calculateTotalfieldAmount = ((jobIdRelatedDetails.serviceCharge * jobIdRelatedDetails.serviceChargeTaxPercentage) / 100) + jobIdRelatedDetails.serviceCharge;
      let initialValue = 0;
      var totalAmountWithTax = 0;
      jobEstimateDto?.filter((data: any) => !data.isDeleted).forEach((item: any) => {
        item.estimateTaxDto.forEach((taxDto: any) => {
          totalAmountWithTax += taxDto.amountWithTax;
        });
      });

      const getPartsTotalValues = flatemap?.reduce((accumulator: any, currentValue: any) => {
        return +accumulator + +currentValue.totalPrice;
      }, initialValue);
      setTotalValue(calculateTotalfieldAmount + +totalAmountWithTax + +getPartsTotalValues);
      setDynamicTaxField(jobEstimateDto[0]?.estimateTaxDto);
    }
  }, [formValue, taxByIdDetails, jobIdRelatedDetails, jobEstimateDto]);


  // useEffect(() => {
  //   let isDeletedfalse = jobIdRelatedDetails?.jobEstimateDto?.filter((data : any)=>data.isDeleted == false)
  //   if (Object.keys(jobIdRelatedDetails).length > 0) {
  //     jobsheetChangessetFieldValue("taxes",isDeletedfalse[0]?.estimateTaxDto)
  //   }
  // }, [jobIdRelatedDetails])


  const getCustomerName = (customerId: any) => {
    const matchedCustomer = customerList.find(cust => cust.id === customerId && cust.isDeleted === false);
    return matchedCustomer?.name;
  };
  const getDealerName = (DealerId: any) => {
    const matchedDealer = dealerList.find(Dealer => Dealer.id === DealerId && Dealer.isDeleted === false);
    return matchedDealer?.name;
  };

  const getInvoiceName = (invoiceId: any) => {
    const matchedInvoice: any = invoiceDropdown.find((invoice: any) => invoice.id === invoiceId);
    return matchedInvoice?.name;
  };



  const convertTaxess = (formValue: any, taxes: any) => {
    return taxes?.map((tax: any) => {
      const matchingForm = formValue["taxes"]?.find((item: any) => item.id === tax.id);
      if (matchingForm) {
        return {
          id: tax.id,
          inoiceId: matchingForm.inoiceId,
          name: tax.name,
          sacNo: tax.sacNo,
          taxPrc: matchingForm.taxPrc,
          amount: tax.amount,
          quantity: tax.quantity,
          unitPrice: tax.unitPrice,
          priority: matchingForm.priority
        };
      } else {
        return {
          id: 0,
          inoiceId: null,
          name: tax.name,
          sacNo: tax.sacNo,
          taxPrc: tax.taxPercentage,
          quantity: tax.quantity,
          unitPrice: tax.unitPrice,
          amount: tax.amount,
          priority: 0
        };
      }
    });
  };

  const convertpartSku = (formValues: any, partSku: any) => {
    return partSku?.map((part: any) => {
      const matchingForm = formValue["partSkus"]?.find((item: any) => item.id === part.id);
      if (matchingForm) {
        return {
          "id": part.id,
          "invoiceId": matchingForm.invoiceId,
          "partSkuId": part.partSkuNumber === matchingForm.number ? matchingForm.partSkuId : null,
          "taxPrc": part.taxPercentage,
          "price": part.price,
          "isScrap": part.foc,
          "number": matchingForm.number,
          "partTaxPercentage": matchingForm.partTaxPercentage,
          "partPrice": matchingForm.partPrice,
          "partNumber": matchingForm.partNumber,
          "partHsnNumber": matchingForm.partHsnNumber,
          "partDescription": matchingForm.partDescription,
          "audioBrand": matchingForm.audioBrand,
          "partId": matchingForm?.partId
        };
      } else {
        return {
          "id": 0,
          "invoiceId": 0,
          "partSkuId": part.id,
          "taxPrc": part.taxPercentage,
          "price": part.price,
          "isScrap": part.foc,
          "number": part.partSkuNumber,
          "partTaxPercentage": part.taxPercentage,
          "partPrice": part.price,
          "partNumber": part.partNumber,
          "partHsnNumber": part.partHsnNumber,
          "partDescription": part.partDescription,
          "audioBrand": null,
          "partId": part.partId
        };
      }
    });
  };


  const convertPartSkucreate = (partskusss: any) => {
    return partskusss?.length > 0 ? partskusss?.map((data: any) => {
      let object = {};
      if (data.foc == true) {
        object = {
          id: 0,
          invoiceId: 0,
          partSkuId: data.id ? data.id : null,
          taxPrc: data.taxPercentage,
          price: data.price,
          isScrap: data.foc,
          number: data.partSkuNumber,
          partTaxPercentage: data.taxPercentage,
          partPrice: data.price,
          partNumber: data.partNumber,
          partHsnNumber: data.partHsnNumber,
          partDescription: data.partDescription,
          audioBrand: null,
          partId: data.partId
        };
      } else {
        object = {
          id: 0,
          invoiceId: 0,
          partSkuId: data.id ? data.id : null,
          taxPrc: data.taxPercentage,
          price: data.price,
          isScrap: data.foc,
          number: data.partSkuNumber,
          partTaxPercentage: data.taxPercentage,
          partPrice: data.price,
          partNumber: data.partNumber,
          partHsnNumber: data.partHsnNumber,
          partDescription: data.partDescription,
          audioBrand: null,
          partId: data.partId
        };
      }
      return object;
    }) : [];
  };

  const handleSubmit = async (values: any) => {
    debugger;
    if (jobEstimateDto?.length > 0) {
      const notDeletedParts = jobEstimateDto?.filter((data: any) => data.isDeleted == false);
      if (!(((+notDeletedParts[0]?.totalEstimation - +values?.total) <= 1) && ((+notDeletedParts[0]?.totalEstimation - +values?.total) >= -1)) && values.locked == true) {
        setMisMatchMessage(`(${values?.total}) doesn't match the estimate total (${notDeletedParts[0]?.totalEstimation}).`);
        setNewErrorVisible(true);
        return;
      }
    }

    if (editJobEstimateDto?.length > 0) {
      const notDeletedParts: any = editJobEstimateDto;
      if (!(((+notDeletedParts[0]?.totalEstimation - +values?.total) <= 1) && ((+notDeletedParts[0]?.totalEstimation - +values?.total) >= -1)) && values.locked == true) {
        setMisMatchMessage(`(${values?.total}) doesn't match the estimate total (${notDeletedParts[0]?.totalEstimation}).`);
        setNewErrorVisible(true);
        return;
      }
    }

    if (id) {
      const { customerName, dealerName, jobId, invoiceTypeName, taxes, discount, partSku, ...remainingData } = values;
      const formattedtax = convertTaxess(formValue, taxes);
      const formattedpartSku = convertpartSku(formValue, partSku);
      let updatedCustomizedData = {
        ...remainingData,
        id: id,
        customerName: getCustomerName(customerName),
        dealerName: getDealerName(dealerName),
        dealerId: dealerName ? dealerName : null,
        jobId: formValue["jobId"],
        jobSheetNumber: formValue["jobSheetNumber"] ? formValue["jobSheetNumber"] : "",
        customerId: customerName ? customerName : null,
        invoiceTypeName: getInvoiceName(invoiceTypeName),
        InvoiceTypeId: invoiceTypeName,
        discount: Number(discount),
        totalAfterDiscount: 0,
        taxes: formattedtax?.length > 0 ? formattedtax : null,
        partSkus: formattedpartSku?.length ? formattedpartSku : null
      };
      dispatch(invoiceUpdateLoading(updatedCustomizedData, id));
    } else {
      const { customerName, dealerName, invoiceTypeName, jobId, taxes, partSku, discount, ...remainingData } = values;
      const createPartSku = convertPartSkucreate(partSku);
      let customizedData = {
        ...remainingData,
        id: 0,
        jobId: (jobId && !job_id) ? jobId : job_id ? jobIdRelatedDetails.id : null,
        customerName: getCustomerName(customerName),
        dealerName: getDealerName(dealerName),
        invoiceTypeName: getInvoiceName(invoiceTypeName),
        InvoiceTypeId: invoiceTypeName,
        dealerId: dealerName ? dealerName : null,
        customerId: customerName ? customerName : null,
        jobSheetNumber: "",
        totalAfterDiscount: 0,
        discount: Number(discount),
        taxes: values.taxes?.length > 0 ? values.taxes?.map((tax: any) => {
          let object = {
            id: 0,
            inoiceId: 0,
            name: tax.name,
            sacNo: tax.sacNo,
            taxPrc: tax.taxPercentage,
            quantity: tax.quantity,
            unitPrice: tax.unitPrice,
            amount: tax.amount,
            priority: 0
          };
          return object;
        }) : null,
        partSkus: createPartSku?.length > 0 ? createPartSku : null
      };
      dispatch(addInvoiceDetailLoading(customizedData));
    }
  };

  const isPartSkuFilled = (values: any) => {
    const partSkuDetails = values.partSku || [];
    return partSkuDetails.every((part: any) => {
      const { id, ...fieldsExceptAlternativeNumber } = part;
      return Object.values(fieldsExceptAlternativeNumber).every(
        (value: any) => value !== null && value !== ""
      );
    });
  };

  const isTaxesFilled = (values: any) => {
    const taxesDetails = values.taxes || [];
    return taxesDetails.every((part: any) => {
      const { id, ...fieldsExceptAlternativeNumber } = part;
      return Object.values(fieldsExceptAlternativeNumber).every(
        (value: any) => value !== null && value !== ""
      );
    });
  };


  const handleAddPartSku = (values: any, push: any) => {
    if (isPartSkuFilled(values)) {
      push({
        "id": 0,
        "partSkuNumber": "",
        "price": 0,
        "taxPercentage": 0,
        "totalPrice": 0,
        "partNumber": "",
        "partDescription": "",
        "partHsnNumber": "",
        "foc": false
      });
    } else { }
  };
  const handleAddPartSku2 = (values: any, push: any) => {

    push({
      "id": 0,
      "partSkuNumber": "",
      "price": 0,
      "taxPercentage": 0,
      "totalPrice": 0,
      "partNumber": "",
      "partDescription": "",
      "partHsnNumber": "",
      "foc": false
    });
  };

  const handleAddTaxes = (values: any, push: any) => {
    if (isTaxesFilled(values)) {
      push({
        name: "",
        sacNo: "",
        taxPercentage: "",
        amount: "",
        taxAmount: "",
        totalAmount: "",
      });
    } else { }
  };

  const handleReset = (resetForm: any) => {
    // navigate(ROUTER.INVOICE_GRID);
    navigate(-1);
    dispatch(resetInvoicePageDetails());
    resetForm();
    // if (id) {
    //   navigate(-1);
    // }
  };

  useEffect(() => {
    if (id) {
      dispatch(getInvoicesIdLoading(id));
      if (edit_job_id) {
        dispatch(getJobEstimateDtoDetailsLoading(edit_job_id));
      }
    }
    if (job_id) {
      dispatch(getInvoicePageJobSheetIdRelatedDetailsLoading(job_id));
      dispatch(jobSheetReturnInvoiceDetailsLoading(job_id));
    }
  }, [id, job_id]);

  useEffect(() => {
    dispatch(getCustomerDropForInvoiceLoading());
    dispatch(getDealerDropForInvoiceLoading());
    dispatch(gettaxesListDropForInvoiceLoading());
    dispatch(getInvoicePageInvoiceListLoading());
    dispatch(getInvoicePageJoblistListLoading());
    dispatch(getInvoiceTypeDropdownListLoading());
    return () => {
      dispatch(resetInvoicePageDetails());
      setFlatmap([]);
      setDynamicTaxField([]);
      setReintializeField({
        comments: "",
        discount: 0,
        invoiceTypeName: 0,
        locked: false,
        taxTypeId: ""
      });
      setTotalValue([]);
    };
  }, []);
  // const [values, setvalues] = useState<any>(0);

  useEffect(() => {
    if (partsSkuIndex != -1) {
      let checkpartsSkuAvailabelOrNot = false;
      const responsePartNumber = partsSkuList[0]?.partNumber;
      const jobEstimatesArray = edit_job_id ? editJobEstimateDto : jobIdRelatedDetails?.jobEstimateDto?.filter((data: any) => data.isDeleted == false);
      if (jobEstimatesArray?.length > 0) {
        for (const estimate of jobEstimatesArray) {
          const estimatePartDto = estimate?.estimatePartDto;
          for (const part of estimatePartDto) {
            if (part.partNumber === responsePartNumber) {
              checkpartsSkuAvailabelOrNot = true;
              break;
            }
          }
          if (checkpartsSkuAvailabelOrNot) {
            break;
          }
        }
      } else {
        checkpartsSkuAvailabelOrNot = true;
      }
      let isInwaranty = (jobIdRelatedDetails?.warrantyStatus == "In Warranty" ? true : false || ediJobIsInwarranty);
      let partTotal = (isInwaranty == false || checkpartsSkuAvailabelOrNot == false) ? partsSkuList?.length > 0 ? partsSkuList?.reduce((accumulator: any, currentValue: any) => {
        let totalPrice = +currentValue?.totalPrice;
        return +accumulator + +totalPrice;
      }, 0) : 0 : 0;

      setDetails({ ...details, index: details.index + 1 });
      let currentData = partsSkuList;
      if (currentData?.length < 2) {
        // for (let i = 0; i < currentData?.length; i++) {
        partsSetFieldValue(`partSku[${partsSkuIndex}].id`, currentData[0]?.id);
        partsSetFieldValue(`partSku[${partsSkuIndex}].taxPercentage`, ((checkpartsSkuAvailabelOrNot == false) || isInwaranty) ? 0 : currentData[0]?.partTaxPercentage);
        partsSetFieldValue(`partSku[${partsSkuIndex}].price`, ((checkpartsSkuAvailabelOrNot == false) || isInwaranty) ? 0 : currentData[0]?.partPrice);
        partsSetFieldValue(`partSku[${partsSkuIndex}].totalPrice`, (checkpartsSkuAvailabelOrNot == false || isInwaranty) ? 0 : currentData[0]?.totalPrice);
        partsSetFieldValue(`partSku[${partsSkuIndex}].partSkuNumber`, currentData[0]?.number);
        partsSetFieldValue(`partSku[${partsSkuIndex}].partNumber`, currentData[0]?.partNumber);
        partsSetFieldValue(`partSku[${partsSkuIndex}].partDescription`, currentData[0]?.partDescription);
        partsSetFieldValue(`partSku[${partsSkuIndex}].partHsnNumber`, currentData[0]?.partHsnNumber);
        partsSetFieldValue(`partSku[${partsSkuIndex}].partId`, currentData[0]?.partId);
        partsSetFieldValue(`partSku[${partsSkuIndex}].foc`, ((checkpartsSkuAvailabelOrNot == false) || isInwaranty) ? true : false);
        // }


        // partsSetFieldValue(`partSku[${partsSkuIndex}].id`, partsSkuList[0]?.id)
        // partsSetFieldValue(`partSku[${partsSkuIndex}].taxPercentage`, ((checkpartsSkuAvailabelOrNot == false) || isInwaranty) ? 0 : partsSkuList[0]?.partTaxPercentage)
        // partsSetFieldValue(`partSku[${partsSkuIndex}].price`, ((checkpartsSkuAvailabelOrNot == false) || isInwaranty) ? 0 : partsSkuList[0]?.partPrice)
        // partsSetFieldValue(`partSku[${partsSkuIndex}].totalPrice`, partTotal)
        // partsSetFieldValue(`partSku[${partsSkuIndex}].partSkuNumber`, partsSkuList[0]?.number)
        // partsSetFieldValue(`partSku[${partsSkuIndex}].partNumber`, partsSkuList[0]?.partNumber)
        // partsSetFieldValue(`partSku[${partsSkuIndex}].partDescription`, partsSkuList[0]?.partDescription)
        // partsSetFieldValue(`partSku[${partsSkuIndex}].partHsnNumber`, partsSkuList[0]?.partHsnNumber)
        // partsSetFieldValue(`partSku[${partsSkuIndex}].foc`, ((checkpartsSkuAvailabelOrNot == false) || isInwaranty) ? true : false)

        setPreviousData(currentData);
        changeTotalByPart(partsSkuIndex, partTotal, passtaxesDetails, partsSetFieldValue);
        handleAddPartSku2(passtaxesDetails, push);
        dispatch(resetPartsSkuIndex());
        setDetails({ ...details, index: details.index + 1 });
      } else {
        setChoosePartsVisible(true);
      }
    }
  }, [partsSkuIndex]);


  const nameForm = useRef(null);
  const changeTotalByPart = async (newindex: number, totalValue: any, formValues: any, setFieldValue: any,) => {

    let totalPartValue = +totalValue > 0 ? +totalValue : 0;
    let serviceCharge = +formValues.serviceCharge > 0 ? +formValues.serviceCharge : 0;
    let serviceChargeTaxPercentage = +formValues.serviceChargeTaxPrc > 0 ? +formValues.serviceChargeTaxPrc : 0;
    let discount = +formValues.discount > 0 ? +formValues.discount : 0;
    const totalAmountbyserviceCharge = (serviceCharge * (serviceChargeTaxPercentage / 100)) + serviceCharge;


    let totalTaxvalueWithSerive = formValues?.taxes?.length > 0 ? formValues?.taxes?.reduce((accumulator: any, currentValue: any) => {
      let totalPrice = +currentValue?.totalAmount;

      return +accumulator + +totalPrice;
    }, totalAmountbyserviceCharge) : totalAmountbyserviceCharge;

    let partValueWithTaxAndServices = formValues?.partSku?.length > 0 ? formValues?.partSku?.reduce((accumulator: any, currentValue: any, indexNumber: number) => {
      let totalPrice = +currentValue.totalPrice;
      if (newindex == indexNumber) {
        totalPrice = totalPartValue;
      }
      return +accumulator + totalPrice;
    }, totalTaxvalueWithSerive) : totalTaxvalueWithSerive;

    if (partValueWithTaxAndServices >= discount) {

      let total = partValueWithTaxAndServices - discount;

      setFieldValue(`total`, parseFloat(total.toFixed(2)));
    } else if ((discount > 0) && (partValueWithTaxAndServices <= discount)) {
      setFieldValue(`total`, parseFloat(partValueWithTaxAndServices.toFixed(2)));
      setFieldValue(`discount`, 0);
      showToast("Discount can't be more than total", { type: "error" });
    }

  };



  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading]);


  const [visible, setVisible] = useState(false);
  useEffect(() => {
    if (error) {
      setVisible(true);
    }
    if (message) {
      showToast(message, { type: "success" });
      if (job_id) {
        setTimeout(() => {
          navigate(ROUTER.JOB_SHEETS_VIEW + "/" + job_id);
        }, 500);
      } else {
        setTimeout(() => {
          // navigate(ROUTER.INVOICE_GRID);
          navigate(-1);
        }, 500);
      }
      dispatch(resetInvoicePageMessages());
    }
  }, [error, message]);
  const hidePopup = () => {
    setVisible(false);
    setNewErrorVisible(false);
    dispatch(resetInvoicePageMessages());
  };


  //amount calculation
  const handleChangeAmount = async (newindex: number, unitPrice: any, taxPercentage: any, setFieldValue: any, taxesDetails: any, partSkuDetails: any, formValues?: any,quantity?:any) => {
    let amountWithoutQty = +unitPrice > 0 ? +unitPrice : 0;
    let qty = +quantity > 0 ? +quantity : 0
    let taxAmountValue = amountWithoutQty * qty;
    setFieldValue(`taxes[${newindex}].amount`, taxAmountValue);
    let taxPercentageValue = +taxPercentage > 0 ? +taxPercentage : 0;
    let discount = +formValues.discount > 0 ? +formValues.discount : 0;
    let taxAmount = parseFloat((taxAmountValue * (taxPercentageValue / 100)).toFixed(2));
    let totalTaxAmount = parseFloat((taxAmount + taxAmountValue).toFixed(2));
    setFieldValue(`taxes[${newindex}].totalAmount`, totalTaxAmount);
    setFieldValue(`taxes[${newindex}].taxAmount`, taxAmount);

    let serviceCharge = +formValues.serviceCharge > 0 ? +formValues.serviceCharge : 0;
    let serviceChargeTaxPercentage = +formValues.serviceChargeTaxPrc > 0 ? +formValues.serviceChargeTaxPrc : 0;
    const totalAmountbyserviceCharge = (serviceCharge * (serviceChargeTaxPercentage / 100)) + serviceCharge;

    let totalTaxvalueWithSerive = taxesDetails?.length > 0 ? taxesDetails?.reduce((accumulator: any, currentValue: any, index: number) => {
      let totalPrice = +currentValue?.totalAmount;
      if (newindex == index) {
        totalPrice = totalTaxAmount;
      }
      return +accumulator + +totalPrice;
    }, totalAmountbyserviceCharge) : totalAmountbyserviceCharge;

    let partValueWithTaxAndServices = partSkuDetails?.reduce((accumulator: any, currentValue: any) => {
      return +accumulator + +currentValue.totalPrice;
    }, totalTaxvalueWithSerive);

    if ((partValueWithTaxAndServices > discount) || (partValueWithTaxAndServices==0 && discount == 0)) {
      let total = partValueWithTaxAndServices - discount;
      
      setFieldValue(`total`, parseFloat(total.toFixed(2)));
    } else if ((discount > 0) && (partValueWithTaxAndServices <= discount)) {
      setFieldValue(`total`, parseFloat(partValueWithTaxAndServices.toFixed(2)));
      setFieldValue(`discount`, 0);
      showToast("Discount can't be more than total", { type: "error" });
    }
  };

  const handleChangeQuantity = async (newindex: number, amountValue: any, taxPercentage: any, setFieldValue: any, taxesDetails: any, partSkuDetails: any, formValues?: any,
    quantity?:any) => {
      
    let amountWithoutqty = +amountValue > 0 ? +amountValue : 0;
    let qty = +quantity > 0 ? +quantity : 0
    let taxAmountValue = amountWithoutqty * qty;
    setFieldValue(`taxes[${newindex}].amount`, taxAmountValue);
    let taxPercentageValue = +taxPercentage > 0 ? +taxPercentage : 0;
    let discount = +formValues?.discount > 0 ? +formValues?.discount : 0;
    let taxAmount = parseFloat((taxAmountValue * (taxPercentageValue / 100)).toFixed(2));
    let totalTaxAmount = parseFloat((taxAmount + taxAmountValue).toFixed(2));
    setFieldValue(`taxes[${newindex}].totalAmount`, totalTaxAmount);
    setFieldValue(`taxes[${newindex}].taxAmount`, taxAmount);

    let serviceCharge = +formValues?.serviceCharge > 0 ? +formValues?.serviceCharge : 0;
    let serviceChargeTaxPercentage = +formValues?.serviceChargeTaxPrc > 0 ? +formValues?.serviceChargeTaxPrc : 0;
    const totalAmountbyserviceCharge = (serviceCharge * (serviceChargeTaxPercentage / 100)) + serviceCharge;

    let totalTaxvalueWithSerive = taxesDetails?.length > 0 ? taxesDetails?.reduce((accumulator: any, currentValue: any, index: number) => {
      let totalPrice = +currentValue?.totalAmount;
      if (newindex == index) {
        totalPrice = totalTaxAmount;
      }
      return +accumulator + +totalPrice;
    }, totalAmountbyserviceCharge) : totalAmountbyserviceCharge;

    let partValueWithTaxAndServices = partSkuDetails?.reduce((accumulator: any, currentValue: any) => {
      return +accumulator + +currentValue.totalPrice;
    }, totalTaxvalueWithSerive);

    if (( partValueWithTaxAndServices > discount ) || ( partValueWithTaxAndServices == 0 && discount == 0 )) {
      let total = partValueWithTaxAndServices - discount;
      setFieldValue(`total`, parseFloat(total.toFixed(2)));
    } else if ((discount > 0) && (partValueWithTaxAndServices <= discount)) {
      setFieldValue(`total`, parseFloat(partValueWithTaxAndServices.toFixed(2)));
      setFieldValue(`discount`, 0);
      showToast("Discount can't be more than total", { type: "error" });
    }
  };

  const changePartSkuPrice = async (newindex: number, price: any, taxPercentage: any, setFieldValue: any, taxesDetails: any, partSkuDetails: any, formValues?: any) => {
    let skuPrice = +price > 0 ? +price : 0;
    let taxPercentageValue = +taxPercentage > 0 ? +taxPercentage : 0;
    let discount = +formValues?.discount > 0 ? +formValues?.discount : 0;
    let taxValue = parseFloat((skuPrice * (taxPercentageValue / 100)).toFixed(2));
    let SkutotalPrice = parseFloat((taxValue + skuPrice).toFixed(2));
    setFieldValue(`partSku[${newindex}].totalPrice`, SkutotalPrice);

    let serviceCharge = +formValues?.serviceCharge > 0 ? +formValues?.serviceCharge : 0;
    let serviceChargeTaxPercentage = +formValues?.serviceChargeTaxPrc > 0 ? +formValues?.serviceChargeTaxPrc : 0;
    const totalAmountbyserviceCharge = (await serviceCharge * (serviceChargeTaxPercentage / 100)) + serviceCharge;

    let partValueWithTaxAndServices = await partSkuDetails?.reduce((accumulator: any, currentValue: any, index: any) => {
      let totalPrice = +currentValue?.totalPrice;
      if (newindex == index) {
        totalPrice = SkutotalPrice;
      }
      return +accumulator + +totalPrice;
    }, totalAmountbyserviceCharge);

    let totalTaxvalueWithService = (await taxesDetails?.length) > 0 ? taxesDetails?.reduce((accumulator: any, currentValue: any, index: number) => {
      return +accumulator + +currentValue.totalAmount;
    }, partValueWithTaxAndServices) : partValueWithTaxAndServices;


    if (totalTaxvalueWithService > discount) {
      let total = totalTaxvalueWithService - discount;
      setFieldValue(`total`, parseFloat(total.toFixed(2)));
    } else if ((discount > 0) && (totalTaxvalueWithService <= discount)) {
      setFieldValue(`total`, parseFloat(totalTaxvalueWithService.toFixed(2)));
      setFieldValue(`discount`, 0);
      showToast("Discount can't be more than total", { type: "error" });
    }
  };

  const handleChangediscount = (tragetValue: any, values: any, setFieldValue: any, taxes: any, skudetails: any, setFieldError: any, setFieldTouched: any, setFieldDisable?: any) => {

    let discount = +tragetValue;
    let serviceCharge = +values.serviceCharge;
    let serviceChargeTaxPercentage = 0;
    if (values.serviceChargeTaxPrc > 0) {
      serviceChargeTaxPercentage = +values.serviceChargeTaxPrc;
    }

    let totalServiceCharge = serviceCharge + (serviceCharge * (serviceChargeTaxPercentage / 100));

    // const taxesTotalAmountWithService = taxes?.length > 0 ? taxes.reduce((acc: any, tax: any) => acc + tax.totalAmount, totalServiceCharge) : totalServiceCharge;
    // const partsAmountWithTaxandService = skudetails?.length > 0 ? skudetails.reduce((acc: any, sku: any) => acc + sku.totalPrice, taxesTotalAmountWithService) : taxesTotalAmountWithService;

    const taxesTotalAmountWithService = taxes?.length > 0
      ? taxes?.reduce((acc: number, tax: any) => acc + Number(tax.totalAmount), totalServiceCharge)
      : totalServiceCharge;

    const partsAmountWithTaxandService = skudetails?.length > 0
      ? skudetails?.reduce((acc: number, sku: any) => acc + Number(sku.totalPrice), taxesTotalAmountWithService)
      : taxesTotalAmountWithService;


    let totalValueWithoutDiscount = parseFloat(partsAmountWithTaxandService.toFixed(2));

    if (totalValueWithoutDiscount > discount) {
      let totalValueWithDiscount = totalValueWithoutDiscount - discount;
      setFieldValue(`total`, parseFloat(totalValueWithDiscount.toFixed(2)));

    } else if ((discount > 0) && (totalValueWithoutDiscount <= discount)) {
      setFieldValue(`total`, parseFloat(totalValueWithoutDiscount.toFixed(2)));
      setFieldValue(`discount`, 0);
      showToast("Discount can't be more than total", { type: "error" });
    }



    // formValue
    // const taxesTotalAmount = taxes?.length > 0 ? taxes.reduce((acc: any, tax: any) => acc + tax.totalAmount, 0) : 0;
    // const skudetailsTotalPrice = skudetails?.length > 0 ? skudetails.reduce((acc: any, sku: any) => acc + sku.totalPrice, 0) : 0;
    // let calculateTotalfieldAmount = ((jobIdRelatedDetails.serviceCharge * jobIdRelatedDetails.serviceChargeTaxPercentage) / 100) + jobIdRelatedDetails.serviceCharge
    // let apitotal = 0
    // let jobidresponseTotal = 0
    // if (!jobIdRelatedDetails && !formValue) {
    //   if (isNaN(totalValue)) {
    //     apitotal = 0
    //   } else {
    //     apitotal = totalValue
    //   }
    // }
    // if (isNaN(calculateTotalfieldAmount)) {
    //   jobidresponseTotal = (((values.serviceCharge * values.serviceChargeTaxPrc) / 100) + values.serviceCharge) - values.discount
    // } else {
    //   jobidresponseTotal = calculateTotalfieldAmount
    // }
    // const updatedSkudetailsTotalPrice = (skudetailsTotalPrice + taxesTotalAmount + apitotal + +jobidresponseTotal);
    // setFieldValue(`total`, updatedSkudetailsTotalPrice);
  };




  //serviceCharge handleChange

  const handleServiceChargeChange = (currentValue: any, formValue: any, taxes: any, partSkus: any, setFieldValue: any) => {
    let serviceCharge = +currentValue;
    let serviceChargeTaxPerc = +formValue?.serviceChargeTaxPrc;
    let discount = +formValue?.discount;
    let totalAmountbyserviceCharge = ((serviceCharge * serviceChargeTaxPerc) / 100) + serviceCharge;

    totalAmountbyserviceCharge = totalAmountbyserviceCharge ? totalAmountbyserviceCharge : 0;

    //total estimate taxes
    const totalTaxeWithServiceCharge = taxes?.length > 0 ? taxes?.reduce((accumalator: any, currentValue: any) => {
      return +accumalator + +currentValue?.totalAmount;
    }, totalAmountbyserviceCharge) : totalAmountbyserviceCharge;

    //total estimates sku
    let totalPartValueWithTaxandService = partSkus?.length > 0 ? partSkus?.reduce((accumalator: any, currentValue: any) => {
      let totalPrice = +currentValue?.totalPrice;
      return +accumalator + +totalPrice;
    }, totalTaxeWithServiceCharge) : totalTaxeWithServiceCharge;

    let total = +parseFloat(totalPartValueWithTaxandService?.toFixed(2));
    if (totalPartValueWithTaxandService > discount) {
      total = total - discount;
    }
    setFieldValue("total", total);

  };


  const dialogContainer = (error: any) => {

    return (<ValidationPopup error={error} visible={visible || newErrorvisible} onHide={hidePopup} />);
  };

  const statusBodyTemplate = (value: any) => {
    let status = value;
    return (
      <StatusHghlighter status={status} />
    );
  };

  useEffect(() => {
    if (!id) {
      jobEstimateDto?.filter((data: any) => data.isDeleted == false)?.map((item: any, index: number) => {
        const { estimatePartDto, estimateTaxDto, ...generalDetails } = item;
        if (generalDetails.updatedByGuid) {
          dispatch(getUpdatedByNameLoading(generalDetails.updatedByGuid, index));
        }
      });
    } else {

      editJobEstimateDto?.filter((data: any) => data.isDeleted == false)?.map((item: any, index: number) => {
        const { estimatePartDto, estimateTaxDto, ...generalDetails } = item;
        if (generalDetails.updatedByGuid) {

          dispatch(getUpdatedByNameLoading(generalDetails.updatedByGuid, index));
        }
      });
    }


  }, [jobEstimateDto, editJobEstimateDto]);


  const transformedData = jobEstimateDto?.filter((data: any) => data.isDeleted == false)?.map((item: any, index: number) => {
    const { estimatePartDto, estimateTaxDto, ...generalDetails } = item;

    return {
      generalDetails: [
        {
          name: "Estimator Comments",
          field: "Estimator Comments",
          value: generalDetails.estimatorComments ? <HtmltoPlainText htmlString={generalDetails?.estimatorComments} /> : ""
        },
        {
          name: "Total Price Of Parts",
          field: "totalPriceOfParts",
          value: generalDetails.totalPriceOfParts
        },
        {
          name: "Job Sheet Service Charge Amount",
          field: "serviceCharge",
          value: String(jobIdRelatedDetails.serviceCharge)
        },
        {
          name: "Total Sac Amount",
          field: "totalSacAmount",
          value: generalDetails.totalSacAmount
        },
        {
          name: "Total Taxes",
          field: "totalTaxes",
          value: generalDetails.totalTaxes
        },
        {
          name: "Grand Total Of Estimate",
          field: "totalEstimation",
          value: generalDetails.totalEstimation
        },
        {
          name: "Approved Status",
          field: "approvedStatus",
          value: generalDetails.approvedStatus ? statusBodyTemplate(generalDetails.approvedStatus) : "-"
        },
        {
          name: "Approval Mode",
          field: "approvalMode",
          value: generalDetails.approvalMode
        },
        {
          name: "Approval Comments",
          field: "approvalComments",
          value: generalDetails.approvalComments ? <HtmltoPlainText htmlString={generalDetails.approvalComments} /> : ""
        },
        {
          name: "Updated By",
          field: "updatedByName",
          value: (generalDetails.updatedByGuid && updatedByName) ? updatedByName?.find((item: any) => item.index == generalDetails.updatedByGuid)?.details?.name : ""
        },
        {
          name: "Created At",
          field: "creationTime",
          value: generalDetails.creationTime ? formatDateTimeFormServerView(generalDetails.creationTime) : ""
        },
        {
          name: "Approved On",
          field: "approvedon",
          value: generalDetails.approvedon ? formatDateTimeFormServerView(generalDetails.approvedon) : ""
        }
      ],
      estimatePartDto: estimatePartDto,
      estimateTaxDto: estimateTaxDto
    };
  });


  const edittransformedData = editJobEstimateDto?.map((item: any, index: number) => {
    const { estimatePartDto, estimateTaxDto, ...generalDetails } = item;
    return {
      generalDetails: [
        {
          name: "Estimator Comments",
          field: "Estimator Comments",
          value: generalDetails.estimatorComments ? <HtmltoPlainText htmlString={generalDetails?.estimatorComments} /> : ""
        },
        {
          name: "Total Price Of Parts",
          field: "totalPriceOfParts",
          value: generalDetails.totalPriceOfParts
        },
        {
          name: "Job Sheet Service Charge Amount",
          field: "serviceCharge",
          value: String(generalDetails.serviceCharge)
        },
        {
          name: "Total Sac Amount",
          field: "totalSacAmount",
          value: generalDetails.totalSacAmount
        },
        {
          name: "Total Taxes",
          field: "totalTaxes",
          value: generalDetails.totalTaxes
        },
        {
          name: "Grand Total Of Estimate",
          field: "totalEstimation",
          value: generalDetails.totalEstimation
        },
        {
          name: "Approved Status",
          field: "approvedStatus",
          value: generalDetails.approvedStatus ? statusBodyTemplate(generalDetails.approvedStatus) : "-"
        },
        {
          name: "Approval Mode",
          field: "approvalMode",
          value: generalDetails.approvalMode
        },
        {
          name: "Approval Comments",
          field: "approvalComments",
          value: generalDetails.approvalComments ? <HtmltoPlainText htmlString={generalDetails.approvalComments} /> : ""
        },
        {
          name: "Updated By",
          field: "updatedByName",
          value: (generalDetails.updatedByGuid && updatedByName) ? updatedByName?.find((item: any) => item.index == generalDetails?.updatedByGuid)?.details?.name : ""
        },
        {
          name: "Created At",
          field: "creationTime",
          value: generalDetails.creationTime ? formatDateTimeFormServerView(generalDetails.creationTime) : ""
        },
        {
          name: "Approved On",
          field: "approvedon",
          value: generalDetails.approvedon ? formatDateTimeFormServerView(generalDetails.approvedon) : ""
        }
      ],
      estimatePartDto: estimatePartDto,
      estimateTaxDto: estimateTaxDto
    };
  });




  const partcolumns: Columns[] = [
    {
      field: "partNumber",
      sortable: false,
      header: "Part",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: true,

    },
    {
      field: "partDescription",
      sortable: false,
      header: "Part Description",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: true,

    },
    {
      field: "quantityRequired",
      sortable: false,
      header: "Quantity Reqd",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: true,

    },
    {
      field: "unitPrice",
      sortable: false,
      header: "Unit Price",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: true,

    },
    {
      field: "partPrice",
      sortable: false,
      header: "Part Price",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: true,

    },
    {
      field: "taxPercentage",
      sortable: false,
      header: "Tax Percentage",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: true,

    },
    {
      field: "totalPriceTax",
      sortable: false,
      header: "Part Tax Amount",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: true,

    },
    {
      field: "totalPrice",
      sortable: false,
      header: "Total Price",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: true,

    },

  ];
  const taxcolumns: Columns[] = [
    {
      field: "name",
      sortable: false,
      header: "Name",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: true,

    },
    {
      field: "sacNumber",
      sortable: false,
      header: "Sac Number",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: true,

    },
    {
      field: "amount",
      sortable: false,
      header: "Amount",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: true,

    },
    {
      field: "taxPercentage",
      sortable: false,
      header: "Tax Percentage",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: true,

    },
    {
      field: "totalTax",
      sortable: false,
      header: "Tax Amount",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: true,

    },
    {
      field: "amountWithTax",
      sortable: false,
      header: "Total Amount",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: true,

    },

  ];


  useEffect(() => {
    setEstimatesDetails(transformedData);
  }, [jobEstimateDto, updatedByName]);

  useEffect(() => {
    if (id) {

      setEstimatesDetails(edittransformedData);
    }
  }, [editJobEstimateDto, updatedByName]);


  const caluclatedZeroEstimated = (index: number, setFieldValue: any, taxes: any, partSkus: any) => {
    if (!id) {
      setFieldValue(`partSku.${index}.price`, 0);
      setFieldValue(`partSku.${index}.taxPercentage`, 0);
      setFieldValue(`partSku.${index}.totalPrice`, 0);
      let initialValue = 0;
      const totalValueonTaxes = taxes?.length > 0 ? taxes?.reduce((accumalator: any, currentValue: any) => {
        return +accumalator + +currentValue.totalAmount;
      }, initialValue) : initialValue;
      //total estimates sku
      setUndoFocDetails(partSkus);
      let totalEstimatespartsSku = partSkus?.reduce((accumalator: any, currentValue: any, currentindex: any) => {
        let totalPrice = +currentValue?.totalPrice;
        if (index == currentindex) {
          totalPrice = 0;
        }
        return +accumalator + +totalPrice;
      }, initialValue);
      let TotalValue = 0;
      if (Object.keys(jobIdRelatedDetails).length > 0) {
        let serviceCharge = jobIdRelatedDetails?.serviceCharge;
        let serviceTax = jobIdRelatedDetails?.serviceChargeTaxPercentage;
        TotalValue = (serviceCharge * serviceTax / 100) + serviceCharge;
      }

      setFieldValue("total", +(totalValueonTaxes + +totalEstimatespartsSku + +TotalValue));
    } else {
      let partSkuDetails = JSON.parse(JSON.stringify(partSkus));
      setUndoFocDetails(partSkus);
      setFieldValue(`partSku.${index}.price`, 0);
      setFieldValue(`partSku.${index}.taxPercentage`, 0);
      setFieldValue(`partSku.${index}.totalPrice`, 0);
      let initialValue = 0;
      const totalValueonTaxes = taxes?.length > 0 ? taxes?.reduce((accumalator: any, currentValue: any) => {
        return +accumalator + +currentValue.totalAmount;
      }, initialValue) : initialValue;
      const partSkustotal = partSkuDetails?.reduce((accumalator: any, currentValue: any, currentindex: number) => {
        if (index === currentindex) {
          currentValue.totalPrice = 0;
        }
        return +accumalator + +currentValue.totalPrice;
      }, initialValue);

      let TotalValue = 0;
      let serviceCharge = formValue?.serviceCharge;
      let serviceTax = formValue?.serviceChargeTaxPrc;
      TotalValue = (serviceCharge * serviceTax / 100) + serviceCharge;
      setFieldValue("total", +(TotalValue + totalValueonTaxes + partSkustotal));
    }
  };

  const undoZeroEstimated = (index: number, setFieldValue: any, formvalues: any, partSkus: any, id: any) => {
    debugger;
    if (partSkus[index]?.partSkuNumber) {
      dispatch(getUndoPartsSkuListLoading(partSkus[index].partSkuNumber, setFieldValue, index, formvalues, id));
    } else {
      return;
    }
  };

  const redirectJobPage = () => {
    navigate(ROUTER.JOB_SHEETS_VIEW + "/" + job_id);
  };
  const footer = (
    <div>
      <Button
        className="btn-primary me-2"
        label="Redirect Job Sheet"
        type="button"
        onClick={redirectJobPage}
      />
    </div>
  );

  const newDialogContainer = () => {
    return (
      <ConfirmDialog
        className="validationPopp"
        visible={true}
        onHide={() => { setErrorValidation(false); }}
        message="This Jobsheet already have a invoice?"
        header="Confirmation"
        icon="pi pi-exclamation-triangle"
        style={{ width: '30vw', display: "flex" }}
        footer={footer}
      />
    );
  };

  const checkInvalidPartAdd = (values: any) => {
    // if(!values.partNumber) {
    //   return true
    // }
    let checkpartsSkuAvailabelOrNot = false;
    const responsePartNumber = partsSkuList[0]?.partNumber;
    const jobEstimatesArray = jobIdRelatedDetails.jobEstimateDto;
    if (jobEstimatesArray?.length > 0) {
      for (const estimate of jobEstimatesArray) {
        const estimatePartDto = estimate.estimatePartDto;
        for (const part of estimatePartDto) {
          if (part.partNumber === responsePartNumber) {
            checkpartsSkuAvailabelOrNot = false;
            break;
          } else {
            checkpartsSkuAvailabelOrNot = true;
          }
        }

      }
    }
    return checkpartsSkuAvailabelOrNot;
  };

  const getBillToDropDown = (setFieldValue: any, dealer: any, customer: any) => {
    let options: any[] = [];
    if ((dealer >= 0 && dealer != "") || (customer >= 0 && customer != "")) {
      if ((dealer >= 0 && dealer != "")) {
        options = [...dealerList?.filter((eachDealer: any) => eachDealer.id == dealer)?.map((eachValue) => {
          let data: any = {};
          data['label'] = eachValue?.name + " (" + eachValue?.phoneNumber + ")";
          data['value'] = eachValue?.userId;
          return data;
        })];
      }
      if ((customer >= 0 && customer != "")) {
        options = [...options, ...customerList?.filter((eachDealer: any) => eachDealer.id == customer)?.map((eachValue) => {
          let data: any = {};
          data['label'] = eachValue?.name + " (" + eachValue?.phoneNumber + ")";
          data['value'] = eachValue?.userId;
          return data;
        })];
      }
      return options.sort((a: any, b: any) => {
        const labelA = a.label.toUpperCase().trim();
        const labelB = b.label.toUpperCase().trim();
        if (labelA < labelB) {
          return -1;
        }
        if (labelA > labelB) {
          return 1;
        }
        return 0;
      });
    } else {
      return options;
    }
  };


  //unUsedSkus={currentUnUsed} values={formValues}
  //scanner page
  const scannerContainer = () => {
    return <InvoiceScanner visible={openScanner} onHide={() => setOpenScanner(false)} allValue={partsSkugenerator} setOpenScanner={setOpenScanner} />;
  };
  const [dialogVisible, setDialogVisible] = useState<boolean>(false);
  const [details, setDetails] = useState<any>(false);
  const [SkuValues, setSkuValues] = useState<any>();
  const [previousData, setPreviousData] = useState<any>([]);
  const OpenDialog = () => {
    return (
      <Dialog header="Scan Sku" visible={dialogVisible} maximizable className='vw30' onHide={() => setDialogVisible(false)}>
        <input
          className="icon-input"
          type="text"
          placeholder="Scan SKU here"
          value={SkuValues}
          onChange={(e: any) => setSkuValues(e.target.values)}
          name="scanSku"
          required={false}
          onKeyDown={(e) => handleKeyPress(e, SkuValues, {})}
        />
      </Dialog>
    );
  };

  const openScannerHandler = (index: number, setFieldValue: any, passpartSkuDetails: any, passtaxesDetails: any, passdeleteState: boolean, reducerState: boolean, setFieldTouched: any, push: any) => {

    setFieldValue(`partSku[${index}].partSkuNumber`, "");
    setFieldTouched(`partSku[${index}].partSkuNumber`, true);
    setFieldValue(`partSku.${index}.price`, "");
    setFieldValue(`partSku.${index}.taxPercentage`, "");
    setFieldValue(`partSku.${index}.totalPrice`, "");
    setFieldValue(`partSku.${index}.partNumber`, "");
    setFieldValue(`partSku.${index}.partDescription`, "");
    setFieldValue(`partSku.${index}.partHsnNumber`, "");
    setPartsSkugenerator({
      index: index,
      setFieldValue: setFieldValue,
      passpartSkuDetails: passpartSkuDetails,
      passtaxesDetails: passtaxesDetails,
      passdeleteState: passdeleteState,
      reducerState: reducerState,
      push: push,
    });
    setOpenScanner(true);
  };

  const handleKeyPress = (event: any, values: any, push: any) => {
    if (event.which == 13 || event.which == 32) {
      event.preventDefault();
    }

    if (!event?.target?.value) return;

    let toScan = event.target.value;

    if (event.key === 'Enter' || event.key === "Tab") {


      // setScanResult(event.target.value);
      // previous API call
      // dispatch(getPostPartSku(event.target.value));

      // new API call

      setSkuValues("");
      dispatch(getPartsListDropForInvoiceLoading(event.target.value, details?.index, details.setFieldValue, details?.partSku, details?.taxes, details?.passdeleteState, details?.reducerState, push));

      // dispatch(getPartSkuWithLocation(values));

    }
  };

  const clearPreviousType = (setFiledValue: any) => {
    setFiledValue("comments", ""),
      setFiledValue("discount", 0),
      setFiledValue("invoiceTypeName", ""),
      setFiledValue("locked", "");
    setFiledValue("taxTypeId", "");
  };

  const clearValues = (setFieldValue: any, index: any, taxes: any, partSku: any, formValues: any) => {
    setFieldValue(`partSku[${index}].partSkuNumber`, "");
    setFieldValue(`partSku.${index}.price`, "");
    setFieldValue(`partSku.${index}.taxPercentage`, "");
    setFieldValue(`partSku.${index}.totalPrice`, "");
    setFieldValue(`partSku.${index}.partNumber`, "");
    setFieldValue(`partSku.${index}.partDescription`, "");
    setFieldValue(`partSku.${index}.partHsnNumber`, "");
    changeTotalByPart(index, 0, formValues, setFieldValue);
  };

  const checkLockFieldShowing = () => {
    let response = false;
    if (job_id || edit_job_id) {
      if (hasFranchiseRoleHandler() || hasFranchiseSubuserRoleHandler()) {
        return response = false;
      } else if (jobIdRelatedDetails?.status == READY_TO_INVOICE) {
        return response = true;
      } else if (jobIdRelatedDetails?.isExchange == true && jobSheetReturnInvoice.length == 0) {
        return response = true;
      } else if (jobIdRelatedDetails?.isExchange == true && jobSheetReturnInvoice.length <= 1 && jobSheetReturnInvoice[0]?.invoiceTypeId == 3) {
        return response = true;
      }
      else if (editJobIsExchange == true && jobSheetReturnInvoice.length == 0) {
        return response = true;
      }
      else if (editJobsheetStatus == READY_TO_INVOICE) {
        return response = true;
      }
      else {
        return response = false;
      }
    } else if (Object.keys(jobIdRelatedDetails).length > 1 && (!job_id && !edit_job_id)) {
      if (jobIdRelatedDetails?.status == READY_TO_INVOICE) {
        return true;
      } else {
        return false;
      }
    } else if (formValue && id) {
      if (formValue?.jobStatus == READY_TO_INVOICE) {
        return true;
      } else if (formValue?.jobSheetNumber == null) {
        return true;
      } else if (formValue?.jobSheetNumber != null && (formValue.invoiceTypeName == salesService || formValue.invoiceTypeName == generalService)) {
        return true;
      }
      else {
        return false;
      }
    }
    else {
      return true;
    }
  };

  const [choosePartsVisible, setChoosePartsVisible] = useState<boolean>(false);
  const onHide = () => {
    setChoosePartsVisible(false);
    dispatch(resetPartsSkuIndex());
  };
  const renderButton = (rowData: any) => {
    return (
      <FeatherIcon icon="check-circle" style={{ color: "green" }} onClick={() => handleClick(rowData)} />
    );
  };

  const handleClick = (rowData: any) => {
    let checkpartsSkuAvailabelOrNot = false;
    const responsePartNumber = rowData?.partNumber;
    const jobEstimatesArray = jobIdRelatedDetails?.jobEstimateDto?.filter((data: any) => data.isDeleted == false);
    if (jobEstimatesArray?.length > 0) {
      for (const estimate of jobEstimatesArray) {
        const estimatePartDto = estimate?.estimatePartDto;
        for (const part of estimatePartDto) {
          if (part.partNumber === responsePartNumber) {
            checkpartsSkuAvailabelOrNot = true;
            break;
          }
        }
        if (checkpartsSkuAvailabelOrNot) {
          break;
        }
      }
    } else {
      checkpartsSkuAvailabelOrNot = true;
    }
    let isInwaranty = (jobIdRelatedDetails?.warrantyStatus == "In Warranty" ? true : false || ediJobIsInwarranty);
    // let partTotal = (isInwaranty == false || checkpartsSkuAvailabelOrNot == false) ? partsSkuList?.length > 0 ? partsSkuList?.reduce((accumulator: any, currentValue: any) => {
    //   let totalPrice = +currentValue?.totalPrice;
    //   return +accumulator + +totalPrice;
    // }, 0) : 0 : 0;
    let partTotal = (isInwaranty == false || checkpartsSkuAvailabelOrNot == false) ? rowData.totalPrice : 0;

    setDetails({ ...details, index: details.index + 1 });
    let currentData = rowData;
    partsSetFieldValue(`partSku[${partsSkuIndex}].id`, rowData.id);
    partsSetFieldValue(`partSku[${partsSkuIndex}].taxPercentage`, ((checkpartsSkuAvailabelOrNot == false) || isInwaranty) ? 0 : rowData.partTaxPercentage);
    partsSetFieldValue(`partSku[${partsSkuIndex}].price`, ((checkpartsSkuAvailabelOrNot == false) || isInwaranty) ? 0 : rowData.partPrice);
    partsSetFieldValue(`partSku[${partsSkuIndex}].totalPrice`, (checkpartsSkuAvailabelOrNot == false || isInwaranty) ? 0 : rowData.totalPrice);
    partsSetFieldValue(`partSku[${partsSkuIndex}].partSkuNumber`, rowData.number);
    partsSetFieldValue(`partSku[${partsSkuIndex}].partNumber`, rowData.partNumber);
    partsSetFieldValue(`partSku[${partsSkuIndex}].partDescription`, rowData.partDescription);
    partsSetFieldValue(`partSku[${partsSkuIndex}].partHsnNumber`, rowData.partHsnNumber);
    partsSetFieldValue(`partSku[${partsSkuIndex}].partId`, rowData.partId);
    partsSetFieldValue(`partSku[${partsSkuIndex}].foc`, ((checkpartsSkuAvailabelOrNot == false) || isInwaranty) ? true : false);


    setPreviousData(currentData);
    changeTotalByPart(partsSkuIndex, partTotal, passtaxesDetails, partsSetFieldValue);
    handleAddPartSku2(passtaxesDetails, push);
    dispatch(resetPartsSkuIndex());
    setDetails({ ...details, index: details.index + 1 });

    setChoosePartsVisible(false);
  };

  const ChoosePart = () => {

    return (
      <>
        <Dialog
          header="Choose Part"
          visible={choosePartsVisible}
          maximizable
          style={{ width: "auto" }}
          onHide={onHide}
        >
          <div>
            <DataTable value={partsSkuList} tableStyle={{ width: "auto" }}>
              <Column field="partNumber" header="Number"></Column>
              <Column field="partDescription" header="Description"></Column>
              <Column field="partPrice" header="Price"></Column>
              <Column field="partTaxPercentage" header="Tax Percentage"></Column>
              <Column field="totalPrice" header="Total Price"></Column>
              <Column header="Select" body={renderButton}></Column>
            </DataTable>


          </div>
        </Dialog>
      </>
    );
  };

  return (
    <div id="view-dealer">
      {visible && dialogContainer(error)}
      {errorValidation && newDialogContainer()}
      {newErrorvisible && dialogContainer(mismatchMessage)}
      {openScanner && scannerContainer()}
      {dialogVisible && OpenDialog()}
      {choosePartsVisible && ChoosePart()}
      <div className="page-content">
        <Container fluid>
          <Breadcrumb
            title="Invoice"
            titlePath={hasFranchiseRoleHandler() ? ROUTER.JOB_SHEETS_GRID : ROUTER.INVOICE_GRID}
            breadcrumbItem={id ? "Edit Invoice" : "Create Invoice"}
          />
          <Row>
            <Card>
              <CardBody>
                <React.Fragment>
                  <Formik
                    onSubmit={handleSubmit}
                    initialValues={initialvalue}
                    validationSchema={!id ? validationSchema : EditValidationSchema}

                    enableReinitialize
                  >
                    {({
                      values,
                      handleChange,
                      touched,
                      errors,
                      handleBlur,
                      resetForm,
                      reset,
                      setFieldValue,
                      setFieldError,
                      setFieldTouched,
                      setFieldDisable,
                      children,
                      ...rest
                    }: any) => {
                      return (

                        <Form onChange={handleChange} ref={nameForm}>
                          <Row>
                            <Col xl={3} lg={4} className="mb-2">
                              <InputCom
                                labelName="Number"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="text"
                                name="number"
                                onBlur={handleBlur}
                                value={values.number}
                                disabled={true}
                                onChange={handleChange}
                                isRequired={false}
                                invalid={
                                  touched["number"] && errors["number"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                            {
                              (!id && !job_id) ?
                                <Col xl={3} lg={4} className="mb-2">
                                  <DropdownInput
                                    options={jobListOption}
                                    name="jobId"
                                    label="Jobsheet"
                                    onChange={(e) => {
                                      handleChange(e);
                                      if (e.target.value != null) {
                                        clearPreviousType(setFieldValue);
                                        setFlatmap(values.partSku);
                                        setTotalValue([]);
                                        setDynamicTaxField(values.taxes);
                                        setReintializeField({
                                          comments: values?.comments,
                                          discount: values?.discount,
                                          invoiceTypeName: values.invoiceTypeName,
                                          locked: values.locked,
                                          taxTypeId: values.taxTypeId
                                        });
                                        dispatch(getInvoicePageJobSheetIdRelatedDetailsLoading(e.target.value));
                                        dispatch(jobSheetReturnInvoiceDetailsLoading(e.target.value));
                                      }
                                    }}
                                    // required={false}
                                    required={values.invoiceTypeName == 3 ? true : false}
                                    value={values.jobId}
                                    onBlur={handleBlur}
                                    disabled={false}
                                    invalid={
                                      touched["jobId"] && errors["jobId"]
                                        ? true
                                        : false
                                    }
                                  />
                                </Col> :
                                ((formValue?.["invoiceTypeId"] == 1 || formValue?.["invoiceTypeId"] == 2) && !formValue?.jobId) ?
                                  <Col xl={3} lg={4} className="mb-2">
                                    <DropdownInput
                                      options={jobListOption}
                                      name="jobId"
                                      label="Jobsheet"
                                      onChange={(e) => {
                                        handleChange(e);
                                        if (e.target.value != null) {
                                          setFlatmap(values.partSku);
                                          setTotalValue([]);
                                          setDynamicTaxField(values.taxes);
                                          setReintializeField({
                                            comments: values?.comments,
                                            discount: values?.discount,
                                            invoiceTypeName: values.invoiceTypeName,
                                            locked: values.locked,
                                            taxTypeId: values.taxTypeId
                                          });
                                          dispatch(getInvoicePageJobSheetIdRelatedDetailsLoading(e.target.value));
                                        }
                                      }}
                                      // required={false}
                                      required={values.invoiceTypeName == 3 ? true : false}
                                      value={values.jobId}
                                      onBlur={handleBlur}
                                      disabled={false}
                                      invalid={
                                        touched["jobId"] && errors["jobId"]
                                          ? true
                                          : false
                                      }
                                    />
                                  </Col>
                                  :
                                  <Col xl={3} lg={4} className="mb-2">
                                    <InputCom
                                      labelName="Jobsheet"
                                      inputclassName="form-control"
                                      labelClassName="form-Label"
                                      type="text"
                                      name="jobId"
                                      onBlur={handleBlur}
                                      value={values.jobId}
                                      disabled={(formValue?.jobSheetNumber || formValue?.jobSheetNumber == null) ? true : job_id ? true : false}
                                      onChange={handleChange}
                                      isRequired={false}
                                      invalid={
                                        touched["jobId"] && errors["jobId"]
                                          ? true
                                          : false
                                      }
                                    />
                                  </Col>
                            }

                            <Col xl={3} lg={4} className="mb-2">
                              <DropdownInput
                                options={dealerOption}
                                name="dealerName"
                                label="Dealer"
                                onChange={handleChange}
                                required={false}
                                value={values.dealerName}
                                onBlur={handleBlur}
                                disabled={values.jobId ? true : false}
                                invalid={
                                  touched["dealerName"] && errors["dealerName"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>

                            <Col xl={3} lg={4} className="mb-2">
                              <DropdownInput
                                options={customerOption}
                                name="customerName"
                                label="Customer"
                                onChange={handleChange}
                                required={false}
                                value={values.customerName}
                                onBlur={handleBlur}
                                disabled={values.jobId ? true : false}
                                invalid={
                                  touched["customerName"] && errors["customerName"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>

                            <Col xl={3} lg={4} className="mb-2">
                              <DropdownInput
                                options={(values.dealerName || values.customerName) ? getBillToDropDown(setFieldValue, values.dealerName, values.customerName) : []}
                                name="billTo"
                                label="Bill To"
                                onChange={handleChange}
                                required={true}
                                value={values.billTo}
                                onBlur={handleBlur}
                                disabled={false}
                                invalid={
                                  touched["billTo"] && errors["billTo"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>

                            <Col xl={3} lg={4} className="mb-2">
                              <DropdownInput
                                options={invoiceDropOption}
                                name="invoiceTypeName"
                                label="Invoice Type"
                                onChange={handleChange}
                                required={true}
                                value={values.invoiceTypeName}
                                onBlur={handleBlur}
                                disabled={values.jobId ? true : false}
                                invalid={
                                  touched["invoiceTypeName"] && errors["invoiceTypeName"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                            <Col xl={3} lg={4} className="mb-2">
                              <InputCom
                                labelName="Service Charge"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="number"
                                name="serviceCharge"
                                onBlur={handleBlur}
                                onKeyDown={(e: any) => {
                                  if (e.key === "+" || e.key === "-") {
                                    e?.preventDefault();
                                  }
                                }}
                                onChange={(e) => {
                                  handleChange(e);
                                  handleServiceChargeChange(e.target.value, values, values.taxes, values.partSku, setFieldValue);
                                }}
                                value={values.serviceCharge}
                                isRequired={true}
                                invalid={
                                  touched["serviceCharge"] && errors["serviceCharge"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                            <Col xl={3} lg={4} className="mb-2">
                              <InputCom
                                labelName="Service Charge Tax Percentage"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="text"
                                disabled={true}
                                name="serviceChargeTaxPrc"
                                onBlur={handleBlur}
                                value={values.serviceChargeTaxPrc}
                                onChange={handleChange}
                                isRequired={true}
                                invalid={
                                  touched["serviceChargeTaxPrc"] && errors["serviceChargeTaxPrc"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                            <Col xl={3} lg={4} className="mb-2">
                              <InputCom
                                labelName="Service Charge Sac Number"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="text"
                                name="serviceChargeSacNo"
                                disabled={(id && values.jobId) || values.jobId || (formValue?.["invoiceTypeId"] == 1 || formValue?.["invoiceTypeId"] == 2) ? true : false}
                                onBlur={handleBlur}
                                value={values.serviceChargeSacNo}
                                onChange={handleChange}
                                isRequired={(id && values.jobId) || values.jobId ? true : false}
                                invalid={
                                  touched["serviceChargeSacNo"] && errors["serviceChargeSacNo"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                            <Col xl={3} lg={4} className="mb-2">
                              <InputCom
                                labelName="Discount"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="number"
                                name="discount"
                                onBlur={(e: any) => {
                                  handleBlur(e);
                                  if (e.target.value > 0) {
                                    handleChangediscount(e.target.value, values, setFieldValue, values.taxes, values.partSku, setFieldError, setFieldTouched, setFieldDisable);
                                  } else {
                                    handleChangediscount(0, values, setFieldValue, values.taxes, values.partSku, setFieldError, setFieldTouched);
                                  }
                                }}
                                onKeyDown={(e: any) => {
                                  if (e.key === "+" || e.key === "-") {
                                    e?.preventDefault();
                                  }
                                }}
                                value={values.discount}
                                onChange={handleChange}
                                isRequired={true}
                                invalid={
                                  touched["discount"] && errors["discount"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                            <Col xl={3} lg={4} className="mb-2">
                              <InputCom
                                labelName="Total"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="text"
                                name="total"
                                onBlur={handleBlur}
                                disabled={true}
                                value={values.total}
                                onChange={handleChange}
                                isRequired={true}
                                invalid={
                                  touched["total"] && errors["total"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                            <Col xl={6} lg={6} className="mb-2">
                              {/* <InputCom
                                labelName="Comment"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="text"
                                name="comments"
                                onBlur={handleBlur}
                                value={values.comments}
                                onChange={handleChange}
                                isRequired={false}
                                invalid={touched["comments"] && errors["comments"] ? true : false}
                              /> */}

                              <TextEditor
                                onChange={handleChange}
                                labelName='Comment'
                                name='comments'
                                required={false}
                                value={values.comments}
                                invalid={touched["comments"] && errors["comments"] ? true : false}
                                setFieldTouched={setFieldTouched}
                                setFieldValue={setFieldValue}
                                onBlur={handleBlur}
                                readOnly={false}
                              />
                            </Col>
                            <Col xl={3} lg={4} className="mb-2">
                              <DropdownInput
                                options={invoiceTaxTypes}
                                name="taxTypeId"
                                label="Tax Type"
                                onChange={handleChange}
                                required={true}
                                value={values.taxTypeId}
                                onBlur={handleBlur}
                                disabled={false}
                                invalid={
                                  touched["taxTypeId"] && errors["taxTypeId"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                            {/* {
                              (checkLockFieldShowing()) && <Col xl={3} lg={4} className="mb-2 mt-3">
                                <CheckboxInput
                                  name="locked"
                                  label="Lock"
                                  value={values["locked"]}
                                  onChange={handleChange}
                                  {...rest}
                                />
                              </Col>
                            } */}
                            {
                              <Col xl={3} lg={4} className="mb-2 mt-3">
                                <CheckboxInput
                                  name="locked"
                                  label="Lock"
                                  disabled={!checkLockFieldShowing()}
                                  value={values["locked"]}
                                  onChange={handleChange}
                                  {...rest}
                                />
                              </Col>
                            }

                          </Row>

                          {
                            jobEstimateDto?.length > 0 && <Row>
                              <div className="view">
                                <div className="general-details">
                                  <b>Invoice Details</b>
                                </div>
                                <div className="subview">
                                  <Row>
                                    {
                                      EstimatesDetails?.map((item: any, index: any) => (
                                        <Accordion key={index} activeIndex={index}>
                                          <AccordionTab header={`Estimate Details`}>
                                            <div key={index}>
                                              <Row>
                                                {item.generalDetails?.map((detail: any, detailIndex: any) => (
                                                  <Col lg={3} key={detailIndex} className={`${detailIndex >= 4 ? "mt-4" : "mt-1"}`}>
                                                    <div className='mb-2'>{detail.name}</div>
                                                    <b>{detail.value}</b>
                                                  </Col>
                                                ))}
                                              </Row>

                                              <Row className='mt-3'>
                                                {item?.estimatePartDto?.length > 0 &&
                                                  <Row>
                                                    <div className="view">
                                                      <div className="general-details">
                                                        <b>Parts</b>
                                                      </div>
                                                      <div className="subview">
                                                        <Row>
                                                          <div className="card ps-0 pe-0">
                                                            <DataTableWrapper
                                                              columnDetails={partcolumns}
                                                              value={item?.estimatePartDto}
                                                            >
                                                            </DataTableWrapper>
                                                          </div>
                                                        </Row>
                                                      </div>
                                                    </div>
                                                  </Row>
                                                }
                                              </Row>
                                              <Row>
                                                {item?.estimateTaxDto?.length > 0 &&
                                                  <Row>
                                                    <div className="view">
                                                      <div className="general-details">
                                                        <b>Taxes</b>
                                                      </div>
                                                      <div className="subview">
                                                        <Row>
                                                          <div className="card ps-0 pe-0">
                                                            <DataTableWrapper
                                                              columnDetails={taxcolumns}
                                                              value={item?.estimateTaxDto}
                                                            >
                                                            </DataTableWrapper>
                                                          </div>
                                                        </Row>
                                                      </div>
                                                    </div>
                                                  </Row>
                                                }
                                              </Row>
                                            </div>
                                          </AccordionTab>
                                        </Accordion>
                                      ))
                                    }
                                  </Row>
                                </div>
                              </div>
                            </Row>
                          }
                          {
                            jobIdRelatedDetails?.warrantyStatus == "In Warranty" &&
                            <div className="view">
                              <div className="general-details">
                                <b>Invoice Details</b>
                              </div>
                              <div className="subview">
                                <Accordion activeIndex={0}>
                                  <AccordionTab header={`Estimate Details`}>
                                    <div>No Estimate found since the system is under warranty</div>
                                  </AccordionTab>
                                </Accordion>
                              </div>
                            </div>
                          }


                          {
                            editJobEstimateDto?.length > 0 && <Row>
                              <div className="view">
                                <div className="general-details">
                                  <b>Invoice Details</b>
                                </div>
                                <div className="subview">
                                  <Row>
                                    {
                                      EstimatesDetails?.map((item: any, index: any) => (
                                        <Accordion key={index} activeIndex={index}>
                                          <AccordionTab header={`Estimate Details`}>
                                            <div key={index}>
                                              <Row>
                                                {item.generalDetails?.map((detail: any, detailIndex: any) => (
                                                  <Col lg={3} key={detailIndex} className={`${detailIndex >= 4 ? "mt-4" : "mt-1"}`}>
                                                    <div className='mb-2'>{detail.name}</div>
                                                    <b>{detail.value ? detail.value : <div className='view-none-text'>-</div>}</b>
                                                  </Col>
                                                ))}
                                              </Row>

                                              <Row className='mt-3'>
                                                {item?.estimatePartDto?.length > 0 &&
                                                  <Row>
                                                    <div className="view">
                                                      <div className="general-details">
                                                        <b>Parts</b>
                                                      </div>
                                                      <div className="subview">
                                                        <Row>
                                                          <div className="card ps-0 pe-0">
                                                            <DataTableWrapper
                                                              columnDetails={partcolumns}
                                                              value={item?.estimatePartDto}
                                                            >
                                                            </DataTableWrapper>
                                                          </div>
                                                        </Row>
                                                      </div>
                                                    </div>
                                                  </Row>
                                                }
                                              </Row>
                                              <Row>
                                                {item?.estimateTaxDto?.length > 0 &&
                                                  <Row>
                                                    <div className="view">
                                                      <div className="general-details">
                                                        <b>Taxes</b>
                                                      </div>
                                                      <div className="subview">
                                                        <Row>
                                                          <div className="card ps-0 pe-0">
                                                            <DataTableWrapper
                                                              columnDetails={taxcolumns}
                                                              value={item?.estimateTaxDto}
                                                            >
                                                            </DataTableWrapper>
                                                          </div>
                                                        </Row>
                                                      </div>
                                                    </div>
                                                  </Row>
                                                }
                                              </Row>
                                            </div>
                                          </AccordionTab>
                                        </Accordion>
                                      ))
                                    }
                                  </Row>
                                </div>
                              </div>
                            </Row>
                          }


                          {/* dynamic form */}
                          {
                            <Row>
                              <Col lg={12} className="dealer-title-header" >
                                <div>Part sku</div>
                              </Col>

                              <FieldArray name="partSku">
                                {({ insert, remove, push }) => (
                                  <div >
                                    {/* {JSON.stringify(values.partSku)} */}
                                    {values.partSku?.length > 0 && values.partSku?.map(
                                      (value: any, index: any) => {
                                        const isLastContact = index === values.partSku.length - 1;
                                        const isFirstContact = index === 0;
                                        return <div key={index}>
                                          <div key={index} className="dynamic-form-container">
                                            <div className="space-equally" id='invoice_gp_container'>
                                              <div className="w-100" id='invoice_group'>
                                                <InputCom
                                                  labelName="Part SKU Number"
                                                  inputclassName="form-control"
                                                  labelClassName={`form-Label ${index == 0 ? "" : "d-sm-none"}`}
                                                  type="text"
                                                  clear={false}
                                                  clickClearButton={() => clearValues(setFieldValue, index, values.taxes, values.partSku, values)}
                                                  cameraIcon={value.partSkuNumber ? false : true}
                                                  camerClick={() => openScannerHandler(index, setFieldValue, values.partSku, values, false, true, setFieldTouched, push)}
                                                  name={`partSku[${index}].partSkuNumber`}
                                                  onBlur={handleBlur}
                                                  onKeyDown={(event: any) => { handleKeyPress(event, values, push); setDetails({ index: index, setFieldValue: setFieldValue, partSku: values.partSku, taxes: values, passdeleteState: false, reducerState: true, setFieldTouched: setFieldTouched }); }}
                                                  onChange={handleChange}
                                                  isRequired={true}
                                                  id="PartSku"
                                                  autoFocus={index === values.partSku.length - 1 ? true : false}
                                                  value={value.partSkuNumber}
                                                  invalid={
                                                    touched.partSku?.[
                                                      index
                                                    ]?.partSkuNumber &&
                                                      errors.partSku?.[index]
                                                        ?.partSkuNumber
                                                      ? true
                                                      : false
                                                  }
                                                  disabled={value.partNumber ? true : false}
                                                />
                                              </div>
                                            </div>
                                            <div className="space-equally">
                                              <InputCom
                                                labelName="Price"
                                                inputclassName="form-control"
                                                labelClassName={`form-Label ${index == 0 ? "" : "d-sm-none"}`}
                                                type="number"
                                                name={`partSku.${index}.price`}
                                                // onBlur={handleBlur}
                                                onBlur={(e) => {
                                                  handleBlur(e);
                                                  changePartSkuPrice(index, e.target.value, value?.taxPercentage, setFieldValue, values.taxes, values.partSku, values);
                                                }}
                                                onKeyDown={(e: any) => {
                                                  if (e.key === "+" || e.key === "-") {
                                                    e?.preventDefault();
                                                  }
                                                }}
                                                onChange={handleChange}
                                                isRequired={true}
                                                value={value.price}
                                                disabled={false}
                                                invalid={touched.partSku?.[index]?.price && errors.partSku?.[index]?.price ? true : false}
                                              />
                                            </div>
                                            <div className="space-equally">
                                              <InputCom
                                                labelName="Tax percentage"
                                                inputclassName="form-control"
                                                labelClassName={`form-Label ${index == 0 ? "" : "d-sm-none"}`}
                                                type="number"
                                                name={`partSku.${index}.taxPercentage`}
                                                onBlur={handleBlur}
                                                disabled={true}
                                                onChange={handleChange}
                                                isRequired={false}
                                                value={value.taxPercentage}
                                                invalid={
                                                  touched.partSku?.[index]?.taxPercentage && errors.partSku?.[index]?.taxPercentage ? true : false}
                                              />
                                            </div>

                                            <div className="space-equally">
                                              <InputCom
                                                labelName="Total price"
                                                inputclassName="form-control"
                                                labelClassName={`form-Label ${index == 0 ? "" : "d-sm-none"}`}
                                                type="number"
                                                name={`partSku.${index}.totalPrice`}
                                                onBlur={handleBlur}
                                                disabled={true}
                                                onChange={handleChange}
                                                isRequired={false}
                                                value={value.totalPrice}
                                                invalid={touched.partSku?.[index]?.totalPrice && errors.partSku?.[index]?.totalPrice ? true : false}
                                              />
                                            </div>
                                            <div className="space-equally">
                                              <InputCom
                                                labelName="Part number"
                                                inputclassName={`form-control`}
                                                labelClassName={`form-Label ${index == 0 ? "" : "d-sm-none"}`}
                                                type="text"
                                                name={`partSku.${index}.partNumber`}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                disabled={true}
                                                isRequired={true}
                                                value={value.partNumber}
                                                invalid={touched.partSku?.[index]?.partNumber && errors.partSku?.[index]?.partNumber ? true : false}
                                              />
                                            </div>
                                            <div className="space-equally">
                                              <InputCom
                                                labelName="Part description"
                                                inputclassName={`form-control`}
                                                labelClassName={`form-Label ${index == 0 ? "" : "d-sm-none"}`}
                                                type="text"
                                                name={`partSku.${index}.partDescription`}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                disabled={true}
                                                isRequired={false}
                                                value={value.partDescription}
                                                invalid={touched.partSku?.[index]?.partDescription && errors.partSku?.[index]?.partDescription ? true : false}
                                              />
                                            </div>
                                            <div className="space-equally">
                                              <InputCom
                                                labelName="Part HSN Number"
                                                inputclassName={`form-control`}
                                                labelClassName={`form-Label ${index == 0 ? "" : "d-sm-none"}`}
                                                type="text"
                                                name={`partSku.${index}.partHsnNumber`}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                disabled={true}
                                                isRequired={false}
                                                value={value.partHsnNumber}
                                                invalid={touched.partSku?.[index]?.partHsnNumber && errors.partSku?.[index]?.partHsnNumber ? true : false}
                                              />
                                            </div>
                                            <div className="space-equally" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                              <CheckboxInput
                                                name={`partSku.${index}.foc`}
                                                label="FOC"
                                                value={value.foc}
                                                disabled={checkInvalidPartAdd(value)}
                                                onChange={(e) => {
                                                  handleChange(e);
                                                  if (e.target.checked) {
                                                    caluclatedZeroEstimated(index, setFieldValue, values.taxes, values.partSku);
                                                  } else {
                                                    // if(id){
                                                    undoZeroEstimated(index, setFieldValue, values, values.partSku, (value.partSkuId ? value.partSkuId : value.id));
                                                    // }else{
                                                    // undoZeroEstimated(index, setFieldValue, values, values.partSku,value.id)
                                                    // }
                                                  }
                                                }}
                                              />
                                            </div>
                                            <div className="space-equally-last" >
                                              <div className="space-equally-2">
                                                <TooltipMEL title="Remove part" position='bottom'>
                                                  <i className="fas fa-times-circle color-red"
                                                    onClick={() => {
                                                      changeTotalByPart(index, 0, values, setFieldValue);
                                                      dispatch(removePartsSkuDetails(index));
                                                      remove(index);
                                                    }
                                                    }>
                                                  </i>
                                                </TooltipMEL>
                                              </div>
                                            </div>
                                          </div>
                                        </div>;
                                      }
                                    )}
                                    <div>

                                      <TooltipMEL title="Add Parts Sku Details" position='bottom'>
                                        <div className="add-btn-container" onClick={() => {
                                          handleAddPartSku(values, push);
                                        }}>
                                          <i className="fas fa-plus-circle"></i><span>Add</span>
                                        </div>
                                      </TooltipMEL>

                                    </div>

                                  </div>
                                )}
                              </FieldArray>
                            </Row>
                          }


                          {/* taxes details changes */}
                          {(jobIdRelatedDetails?.warrantyStatus != "In Warranty" && !ediJobIsInwarranty) &&
                            <TaxesDetails handleChange={handleChange} values={values} handleBlur={handleBlur} errors={errors} setFieldValue={setFieldValue} touched={touched} handleChangeAmount={handleChangeAmount} handleChangeQuantity={handleChangeQuantity} handleAddTaxes={handleAddTaxes}
                            />
                          }


                          <div className="d-flex justify-content-end mt-2">
                            <TooltipMEL title={id ? "Cancel" : "Reset The Form"} position='top'>
                              <Button
                                className="secondary-btn me-2"
                                label={"Cancel"}
                                type="button"
                                onClick={() => handleReset(resetForm)}
                              />
                            </TooltipMEL>
                            <TooltipMEL title={id ? "Update Invoice" : "Create Invoice"} position='top'>
                              <Button
                                className="btn-primary me-2 btn-primary-shadow"
                                label={id ? "Update Invoice" : "Create Invoice"}
                                type="submit"
                              />
                            </TooltipMEL>
                          </div>
                          <br />
                        </Form>
                      );
                    }}
                  </Formik>
                </React.Fragment>
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default AddEditInvoice;