import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ValidationPopup from 'src/components/Common/ValidationMessage/ValidationPopup';
import { ACTIVE, DELETED, LOCKED } from 'src/constants/commonConstants';
import { Columns, LazyTableState } from "src/models/components/dataTableWrapperModel";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import { useToast } from 'src/components/Common/ReactToaster';
import CustomizeTable from "src/components/Common/CustomizeTable/CustomizeTable";
import ColumnAction from 'src/components/Common/ColumnAction/ColumnAction';
import { useNavigate } from 'react-router';
import { ROUTER } from 'src/constants/routes';
import { Form, Formik } from 'formik';
import * as Yup from "yup";
import { Button, Col, Input, Row, UncontrolledTooltip } from 'reactstrap';
import Select from "src/components/Common/Select";
import { Option } from "src/models/components/inputWrapperModel";
import DataTableWrapper from 'src/components/Grid/DataTableWrapper';
import FeatherIcon from "feather-icons-react";
import ExportFile from 'src/components/Common/ExcelPdf Export/ExportFile';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import SortIcon from 'src/components/Common/CustomFilterandSortIcon/SortIcon';
import FilterIcon from 'src/components/Common/CustomFilterandSortIcon/FilterIcon';
import { RootReducerState } from 'src/store/reducers';
import { CurrencyFormatter, formatDateMEL, hasCustomerRoleHandler, hasDealerRoleHandler, saveAsExcelFile } from 'src/helpers/common_helpers';
import StatusHghlighter from 'src/components/Common/StatusHighlighter/StatusHighlighter';
import DeleteModal from '../Calendar/DeleteModal';
import ArchiveModal from "../Calendar/DeleteModal";
import { clearReceivedPaymentMessage, getInvoiceReceivedPaymentLoading, getReceivedPaymentListLoading, getUserTypeByUuidLoading } from 'src/store/receivedPayment/action';
import { ColumnFilterElementTemplateOptions } from 'primereact/column';
import { API } from 'src/util/api';
import { receivedPaymentList } from 'src/models/pages/receivedPaymentModel';
import { customizeTableColumns } from 'src/models/components/CustomizeTableColumns';

const courierListConverson = (values: any) => {
  const modifeidValue = values?.items?.map((eachValue: any) => {
    let data: any = {};
    data['id'] = eachValue?.receivedPaymentId;
    data['invoices'] = eachValue?.invoiceId;
    data['paymentDate'] = eachValue?.paymentDate;
    data['amount'] = eachValue?.total;
    data['recipientName'] = eachValue?.recipientName;
    data['InvoiceId'] = eachValue?.invoicenumber;
    return data;
  });
  return modifeidValue;
};

const ReceivedPayment = () => {
  const filterDetails: DataTableFilterMeta = {
    InvoiceId: { value: "", matchMode: "contains" },
    paymentDate: { value: "", matchMode: "contains" },
    PaymentStartDate: { value: "", matchMode: "contains" },
    PaymentEndDate: { value: "", matchMode: "contains" },
    DealerId: { value: "", matchMode: "contains" },
    CustomerId: { value: "", matchMode: "contains" },
    recipientName: { value: "", matchMode: "contains" },
    
  };

  useEffect(() => {
    dispatch(getInvoiceReceivedPaymentLoading());
  }, []);

  let initialLazyState: LazyTableState = {
    first: 0,
    rows: 10,
    page: 1,
    sortField: "CreationTime",
    sortOrder: -1,
    filters: filterDetails,
  };

  const [visible, setVisible] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedStatus, setselectedStatus] = useState(ACTIVE);
  const [rowData, setRowData] = useState<any | null>(null);
  const [lazyState, setLazyState] = useState(initialLazyState);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedReceivedPayment, setSelectedReceivedPayment] = useState<any[]>([]);
  const [archiveModal, setArchiveModal] = useState(false);
  const [isTooltipVisible, setIsTooltipVisible] = useState<boolean>(false);
  const [showCustomizeTable, setShowCustomizeTable] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [filter, setFilter] = useState(false);
  const dt = useRef<DataTable<any>>(null);
  const { loading, error, message, receivedPaymentList, invoiceList, getUserDetails } = useSelector((state: RootReducerState) => state.receivedPaymentReducer);
  const [pdfLoading, setPdfLoading] = useState<boolean>(false);

  const { total } = useSelector((state: RootReducerState) => state.receivedPaymentReducer.receivedPaymentList);

  const loginUser: any = localStorage.getItem("authUser");
  const uuid = JSON.parse(loginUser)?.uid;
  useEffect(() => {
    if (uuid) {
      dispatch(getUserTypeByUuidLoading(uuid));
    }
  }, [uuid]);
 

  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading || pdfLoading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading, pdfLoading]);
  
  const modifiedValues: any[] = courierListConverson(receivedPaymentList);

  const navigate = useNavigate();

  const onCreateClickHandler = () => {
    navigate(ROUTER.RECEIVED_PAYMENT_CREATE);
  };

  const viewHandler = (rowData: receivedPaymentList) => {
    
    navigate(`${ROUTER.RECEIVED_PAYMENT_VIEW}/${rowData["id"]}`);
  };

  const editHandler = (rowData: receivedPaymentList) => {
    navigate(`${ROUTER.EDIT_COURIER}/${rowData["id"]}`);
  };
  const deleteHandler = (rowData: receivedPaymentList) => {
    setDeleteModal(true);
    setRowData(rowData);
  };

  useEffect(() => {
    if (error) {
      setVisible(true);
    }
    if (message) {
      showToast(message, { type: "success" });
      dispatch(clearReceivedPaymentMessage());

    }
  }, [error, message]);


  const invoiceDropList = useMemo(() => {
    return invoiceList.map((eachInvoice: any) => {
      let data: any = {};
      data['value'] = eachInvoice.id;
      data['label'] = eachInvoice.number;
      return data;
    }).sort((a: any, b: any) => {
      const labelA = a?.label?.toUpperCase().trim();
      const labelB = b?.label?.toUpperCase().trim();
      if (labelA < labelB) {
        return -1;
      }
      if (labelA > labelB) {
        return 1;
      }
      return 0;
    });
  }, [invoiceList]);

  const customerFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return (<>
      <Dropdown
        options={invoiceDropList?.length > 0 ? invoiceDropList : []}
        name="invoiceId"
        optionLabel="label"
        onChange={(e: DropdownChangeEvent) => {
          options.filterCallback(e.value, options.index);
          return {};
        }}
        required={true}
        value={options.value}
        filter
        virtualScrollerOptions={{ itemSize: 35 }}
      />
    </>);
  };

  const actionBodyTemplate = (rowData: receivedPaymentList, value: string) => {
    
    return (
      <>
        {
          (!rowData?.isDeleted && !rowData?.locked) ? (
            <ColumnAction
              displayData={rowData[value] as string}
              isView={true}
              onViewClick={() => viewHandler(rowData)}
            />
          ) : (
            <ColumnAction
              displayData={rowData[value] as string}
              undo={rowData.isDeleted ? true : false}
              onDeleteClick={() => deleteHandler(rowData)}
            />
          )
        }
      </>
    );
  };

  const statusBodyTemplate = (rowData: receivedPaymentList, field: string) => {
    let status = rowData[field];
    return (
      <StatusHghlighter status={status} />
    );
  };

  const currencyBodyTemplate = (rowData: any, field: string, code: string) => {
    let amount = rowData[field];
    return (<div className="align-right">{CurrencyFormatter({ value: amount, currencyCode: code })}</div>);
  };

  const dateFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return (<>
      <label htmlFor="">Start Date</label>
      <Input type="date" required value={options?.value?.startDate || ""} max={options?.value?.endDate ? options?.value?.endDate : ""} onChange={(e: any) => {
        options;
        let modifiedDate: any = options.value || { startDate: "", endDate: "" };
        modifiedDate['startDate'] = e.target.value;
        options.filterCallback(modifiedDate, options.index);
      }} />
      <br />
      <label htmlFor="">End Date</label>
      <Input type="date" required value={options?.value?.endDate || ""} min={options?.value?.startDate ? options?.value?.startDate : ""} onChange={(e: any) => {
        options;
        let modifiedDate: any = options.value || { startDate: "", endDate: "" };
        modifiedDate['endDate'] = e.target.value;
        options.filterCallback(modifiedDate, options.index);
      }} />
    </>);
  };

  const headerTemplate = (val: any) => {
    return (
      <div className="d-flex justify-content-start">
        <div className="d-flex justify-content-end" style={{ width: "100px" }}  >
          <div className="align-right">
            {val}
          </div>
        </div>
      </div>
    );
  };

  const columns: Columns[] = [
    {
      field: "InvoiceId",
      sortable: false,
      header: "Invoice Number",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      body: (rowData) => actionBodyTemplate(rowData, "InvoiceId"),
      filterElement: customerFilterTemplate,
      isDefault: true,
      frozen: true,
      isStatic: true,
    },
    {
      field: "paymentDate",
      sortable: true,
      header: "Payment Date",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap" },
      body: (rowData) => formatDateMEL(rowData.paymentDate),
      filterElement: dateFilterTemplate,
      isDefault: true,
      isStatic: false,
    },
    {
      field: "recipientName",
      sortable: true,
      header: "Recipient Name",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
    },
    {
      field: "amount",
      sortable: false,
      header: "Total",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "150px" },
      isDefault: true,
      isStatic: false,
      body: (rowData) => headerTemplate(currencyBodyTemplate(rowData, 'amount', 'INR'))
    },
  ];

  const options: Option[] = [
    { label: "Active", value: "Active" },
    { label: "Deleted", value: "Deleted" },
  ];


  const [visibleColumns, setVisibleColumns] = useState(columns.filter((eachColumn: Columns) => eachColumn.isDefault));

  useEffect(() => {
    setVisibleColumns(columns.filter((eachColumn: Columns) => eachColumn.isDefault));
  }, [invoiceList]);

  const dispatch = useDispatch();
  const { showToast } = useToast();

  const hidePopup = () => {
    setVisible(false);
    dispatch(clearReceivedPaymentMessage());
  };

  const defaultColumns = {
    // ...columns[0]
  };

  const handleDeleteUsers = (rowData: any, length: number = 0, index: number = 0) => {
    let payload = {
      id: rowData["id"],
      undo: rowData['isDeleted'] ?? false,
      lazyState,
    };
    setDeleteModal(false);
    setRowData(null);
    if (index + 1 === length || index === length) {
      setSelectAll(false);
      setSelectedReceivedPayment([]);
    }
  };

  const customizeTableColumns: customizeTableColumns[] = columns.map((eachColumns: Columns) => {
    let data: any = {};
    data['field'] = eachColumns.field;
    data['value'] = visibleColumns.some((sCol: any) => sCol.field === eachColumns.field);
    data['isDisable'] = eachColumns.isStatic;
    data['header'] = eachColumns.header;
    return data;
  }).filter((eachColumns: customizeTableColumns) => eachColumns.field != 'select');

  const archiveHandler = () => {
    setSelectAll(false);
    setSelectedReceivedPayment([]);
    setArchiveModal(false);
  };

  const dialogContainer = (error: any) => {
    return (<ValidationPopup error={error} visible={visible} onHide={hidePopup} />);
  };

  const customizeTableCancelHandler = () => {
    setShowCustomizeTable(false);
  };

  const customizeColumnSubmitHandler = (customizeColumnObject: any[]) => {
    let orderedSelectedColumns = columns.filter((col) => customizeColumnObject.some((sCol: any) => sCol === col.field));
    setVisibleColumns([...orderedSelectedColumns]);
    setShowCustomizeTable(false);
  };

  const initialValues = {
    status: "",
  };

  const validationSchema = Yup.object({});

  const handleSubmit = (formValue: any) => {
    setselectedStatus(formValue);
    if (formValue == ACTIVE) {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          // IsDeleted: { value: "false", matchMode: "contains" },
        },
      }));
    }
    else if (formValue == DELETED) {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          // IsDeleted: { value: "true", matchMode: "contains" },
        },
      }));
    } else {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          // IsDeleted: { value: null, matchMode: "contains" },
        },
      }));
    }
    setIsTooltipVisible(false);
  };

  const handleReset = (formValue: any) => {
    setLazyState((state) => ({
      ...state,
      filters: {
        ...state.filters,
        status: {
          ...state.filters.status,
          value: "",
        }
      },
    }));
  };



  const handleStatusBlur = () => { };

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

  const changeColumnDetails = (values: any[]) => {
    let modifiedColumnDetails = values.map((eachValue) => {
      let data: any = {};
      data['Invoice Number'] = eachValue?.InvoiceId;
      data['Payment Date'] = formatDateMEL(eachValue?.paymentDate);
      data['Amount'] = eachValue?.amount;
      data['Recipient Name'] = eachValue?.recipientName;
      return { ...data };
    });
    return modifiedColumnDetails;
  };

  const saveDataAsExcel = (values: any[], fileName: string) => {
    const modifiedColumnDetails = changeColumnDetails(values);
    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(modifiedColumnDetails);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array'
      });
      saveAsExcelFile(excelBuffer, fileName);
    });
  };

  const saveDataAsPdf = (values: any[], columns: any[], fileName: string) => {
    const destructureedData = values.map((data) => {
      let datas = {
        InvoiceId: data?.InvoiceId,
        paymentDate: formatDateMEL(data?.paymentDate),
        amount: data?.amount,
        recipientName: data?.recipientName,
        invoicenumber: data?.invoicenumber,
      };
      return datas;
    });
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then((autoTable) => {
        columns = columns.filter((a: any) => isNaN(Number(a?.field)));
        let exportColumns = columns.map((col) => ({ title: col.header, dataKey: col.field }));
        const doc = new (jsPDF as any).default({
          orientation: 'landscape',
          format: 'a3',
        });
        const columnStyles = getColumnStyles();
        autoTable.default(doc, {
          useCss: true,
          theme: "striped",
          showHead: "everyPage",
          columns: exportColumns,
          columnStyles: columnStyles,
          body: destructureedData,
          showFoot: "everyPage",
          tableWidth: "auto",
        });
        doc.save(`${fileName}.pdf`);
      });
    });
  };

  const getColumnStyles = () => {
    const columnStyles: any = {};
    columnStyles[`amount`] = { halign: 'right' };
    return columnStyles;
  };
  const fetchData = async (isPdf: boolean) => {
    let modifiedLazyState: any = JSON.parse(JSON.stringify({ ...lazyState, rows: total > 5000 ? 5000 : total < 1 ? 1 : total, first: 0, }));

    if (modifiedLazyState.filters && modifiedLazyState.filters.paymentDate) {
      modifiedLazyState.filters.paymentDate.value = "";
    }
    if (hasDealerRoleHandler()) {
      modifiedLazyState.filters.DealerId.value = getUserDetails?.extraProperties?.DealerId;
    } else if (hasCustomerRoleHandler()) {
      modifiedLazyState.filters.CustomerId.value = getUserDetails?.extraProperties?.CustomerId;
    }

    try {
      setPdfLoading(true);
      const response = await API.getReceivedPaymentGrid(modifiedLazyState);
      if (!response) {
        throw new Error('Network response was not ok');
      }
      const modifeidValue = response?.data?.items?.map((eachValue: any) => {
        let data: any = {};
        data['id'] = eachValue?.receivedPaymentId;
        data['invoices'] = eachValue?.invoiceId;
        data['paymentDate'] = eachValue?.paymentDate;
        data['amount'] = eachValue?.total;
        data['InvoiceId'] = eachValue?.invoicenumber;
        data['recipientName'] = eachValue?.recipientName;
        return data;
      });
      // let resp=await courierListConverson(response?.data?.items)
      if (isPdf) {
        saveDataAsPdf(modifeidValue, columns, "received payment");
      } else {
        saveDataAsExcel(modifeidValue, "received payment");
      }
      setPdfLoading(false);
    } catch (error: any) {
      showToast(error.message, { type: "error" });
      setPdfLoading(false);

    } finally {
      setPdfLoading(false);
    }
  };

  const csvClickHandler = () => {
    // saveDataAsExcel(modifiedValues, "received payment");
    fetchData(false);

  };

  const pdfClickHandler = () => {
    // saveDataAsPdf(modifiedValues, columns, "received payment");
    fetchData(true);

  };

  const customizeTableClickHandler = () => {
    setShowCustomizeTable(true);
  };
  const clearFilter = () => {
    setLazyState(initialLazyState);
    let modifiedLazyState: any = { ...initialLazyState };

    if (modifiedLazyState.filters && modifiedLazyState.filters.paymentDate) {
      modifiedLazyState.filters.paymentDate.value = "";
    }
    if (hasDealerRoleHandler()) {
      modifiedLazyState.filters.DealerId.value = getUserDetails?.extraProperties?.DealerId;
      setLazyState(modifiedLazyState);
    } else if (hasCustomerRoleHandler()) {
      modifiedLazyState.filters.CustomerId.value = getUserDetails?.extraProperties?.CustomerId;
      setLazyState(modifiedLazyState);
    } else {
      setLazyState(modifiedLazyState);
    }

  };
  const header =
    <div className="table-header-container">
      <div>
        Received Payment
      </div>
      <div className="flex-grow-1"></div>
      <div className="table-header-container-item">
        <div style={{ display: "flex" }}>
          <ExportFile exportExcel={csvClickHandler} exportPdf={pdfClickHandler} clearFilter={clearFilter} isFilter={true} />
          <Button type="button" className="customize-table-button mt-1" id="ScheduleUpdateTooltip"
            onMouseEnter={() => setTooltipOpen(true)}
            onMouseLeave={() => setTooltipOpen(false)} data-btn="btn" onClick={customizeTableClickHandler}>
            <FeatherIcon icon="sliders" />
          </Button>
        </div>
        {
          (!hasCustomerRoleHandler() && !hasDealerRoleHandler()) ? <div className="reponsive_position">
            <Button type="button" className="btn btn-primary add_btn" onClick={onCreateClickHandler}>
              <FeatherIcon icon="plus-circle" className={"feathet-icon"} />
              New Received Payment
            </Button>
          </div> : <></>
        }

        <UncontrolledTooltip
          placement="bottom"
          target="ScheduleUpdateTooltip"
          isOpen={tooltipOpen}
          toggle={toggleTooltip}
          innerClassName="tooltip-color"
        >
          Choose fields you want to see in the table
        </UncontrolledTooltip>
      </div>

    </div>;

  const onSelectionChange = (event: any) => {
    const value = event.value;
    setSelectedReceivedPayment(value);
    // setSelectAll(value.length === totalRecords);
    // setSelectAll(value.length === paginatorCount);
  };

  const onSelectAllChange = (event: any) => {
    const selectAll = event.checked;
    if (selectAll) {
      if (selectedStatus == LOCKED) {
        setSelectAll(false);
        setSelectedReceivedPayment([]);
      } else if (selectedStatus == ACTIVE) {
        let len = modifiedValues?.length;
        let filterLen = modifiedValues?.filter((eachValue: any) => !eachValue.locked).length;
        if (len === filterLen) {
          setSelectAll(true);
          setSelectedReceivedPayment(modifiedValues);
        } else {
          setSelectAll(false);
          setSelectedReceivedPayment(modifiedValues?.filter((eachValue: any) => !eachValue.locked));
        }
      }
      else {
        setSelectAll(true);
        setSelectedReceivedPayment(modifiedValues);
      }
    } else {
      setSelectAll(false);
      setSelectedReceivedPayment([]);
    }
  };

  const callbackFunction = (lazyState: LazyTableState) => {
    let modifiedLazyState: any = { ...lazyState };

    if (modifiedLazyState.filters && modifiedLazyState.filters.paymentDate) {
      modifiedLazyState.filters.paymentDate.value = "";
    }
    if (hasDealerRoleHandler()) {
      modifiedLazyState.filters.DealerId.value = getUserDetails?.extraProperties?.DealerId;
      dispatch(getReceivedPaymentListLoading(modifiedLazyState));
    } else if (hasCustomerRoleHandler()) {
      modifiedLazyState.filters.CustomerId.value = getUserDetails?.extraProperties?.CustomerId;
      dispatch(getReceivedPaymentListLoading(modifiedLazyState));
    } else {
      dispatch(getReceivedPaymentListLoading(modifiedLazyState));
    }


  };

  useEffect(() => {
    setSelectAll(false);
    setSelectedReceivedPayment([]);
    callbackFunction(lazyState);
    return () => {
      // dispatch(clearCourierMessage());
    };
  }, [lazyState, getUserDetails]);

  const onSort = (event: any) => {
    setLazyState(event);
  };
  const onFilter = (event: any) => {
    event["first"] = 0;
    event['filters']['PaymentStartDate']['value'] = event?.filters?.paymentDate?.value?.startDate ? event?.filters?.paymentDate?.value?.startDate : '';
    event['filters']['PaymentEndDate']['value'] = event?.filters?.paymentDate?.value?.endDate ? event?.filters?.paymentDate?.value?.endDate : '';
    setLazyState(event);
  };

  const onPage = (event: any) => {
    setLazyState(event);
  };


  return (
    <React.Fragment>
      <div className="page-content">
        {visible && dialogContainer(error)}
        <DeleteModal
          show={deleteModal}
          undo={selectedStatus == DELETED ? true : false}
          onDeleteClick={() => {
            if (rowData) {
              return handleDeleteUsers(rowData);
            }
            if (selectedReceivedPayment?.length > 10) {
              showToast("Cannot delete more than 10 items at once", { type: "error" });
              setSelectedReceivedPayment([]);
              setDeleteModal(false);
              setSelectAll(false);
              return setRowData(null);
            }

            if (selectedReceivedPayment.length > 0) {
              for (let i = 0; i < selectedReceivedPayment.length; i++) {
                handleDeleteUsers(selectedReceivedPayment[i], selectedReceivedPayment.length, i);
              }
            }
          }}
          onCloseClick={() => {
            setDeleteModal(false);
            setRowData(null);
          }}
        />
        <ArchiveModal
          show={archiveModal}
          message="you want to archieve these purchase order?"
          deleteLabel="Yes"
          closeLabel="No"
          onDeleteClick={() => {
            archiveHandler();
          }}
          onCloseClick={() => {
            setArchiveModal(false);
            setSelectAll(false);
            setSelectedReceivedPayment([]);
          }}
        />
        {showCustomizeTable && <CustomizeTable onCancelHandler={customizeTableCancelHandler} onCustomizeColumnSubmitHandler={customizeColumnSubmitHandler} columns={customizeTableColumns} />}


        {filter && (
          <div className="card">
            <div className="card-body">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                onReset={handleReset}
                enableReinitialize
              >
                {({ values, handleChange }) => {
                  return (
                    <Form onChange={handleChange}>
                      <Row id="export-search-clear">
                        <Col xs={12} lg={7} >

                        </Col>
                        <Col xs={12} lg={5} className="select-clear-search-container d-flex">
                          <Select
                            label=""
                            name="status"
                            onBlur={handleStatusBlur}
                            value={values.status}
                            onChange={handleChange}
                            options={options}
                            invalid={false}
                          />
                          <Button className="btn btn-primary ms-2" color="primary" type="reset">
                            Clear </Button>
                          <Button className="btn btn-primary ms-2" color="primary" type="submit">
                            Search </Button>
                        </Col>

                      </Row>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        )}
        <DataTableWrapper
          ref={dt}
          columnDetails={visibleColumns}
          header={header}
          value={modifiedValues}
          sortIcon={(setOrder) => <SortIcon order={setOrder} />}
          filterIcon={FilterIcon}
          lazy
          paginator={true}
          stripedRows={true}
          rowHover={true}
          // filterDisplay="row"
          rowsPerPageOptions={[5, 10, 25, 50]}
          totalRecords={total}
          loading={loading}
          emptyMessage="No details found."
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Displaying Received Payment  {last} records of {totalRecords} in total"
          selection={selectedReceivedPayment}
          onSelectionChange={onSelectionChange}
          selectAll={selectAll}
          onSelectAllChange={onSelectAllChange}
          first={lazyState.first}
          onSort={onSort}
          sortField={lazyState.sortField}
          sortOrder={lazyState.sortOrder}
          onFilter={onFilter}
          filters={lazyState.filters}
          onPage={onPage}
          rows={lazyState.rows}
          selectionMode="checkbox"
        // rowClassName={rowClassName}
        >
        </DataTableWrapper>
      </div>
    </React.Fragment>
  );
};

export default ReceivedPayment;